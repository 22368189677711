import React, { useContext, useState } from 'react';
import { Nav, NavDropdown, NavItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import UserContext from '../../contexts/UserContext';
import { AccountType } from '../../types/enums';
import { ViewModels } from '../../types/viewModels';
import { AsyncButton } from '../../../sharedCommonComponents/components/AsyncButton';
import { SharedUrls } from '../../../sharedHealthComponents/navigation/Urls';

interface LoggedInUserProps {
    onLogOut: () => Promise<void>;
}

export const LoggedInUser = (props: LoggedInUserProps) => {

    const { onLogOut } = props;
    const user = useContext(UserContext)!;
    const [ isLoggingOut, setIsLoggingOut ] = useState<boolean>(false);
    const navigate = useNavigate();

    const logOut = async () => {
        setIsLoggingOut(true);
        try {
            await onLogOut();
        } finally {
            setIsLoggingOut(false);
        }
    }

    let name = resolveText("Guest");
    switch(user.accountType) {
        case AccountType.Sharer:
        case AccountType.HealthProfessional:
        case AccountType.Researcher:
        case AccountType.Pharmacy:
        case AccountType.Admin:
            const personUserViewModel = user as ViewModels.PersonUserViewModel;
            name = personUserViewModel.profileData?.firstName;
            break;
        case AccountType.PublicHealthAgency:
            const publicHealthAgencyUserViewModel = user as ViewModels.PublicHealthAgencyUserViewModel;
            name = publicHealthAgencyUserViewModel.agencyName;
            break;
    }

    return (
        <Nav className='ms-auto'>
            {user.accountType !== AccountType.EmergencyGuest
            ? <NavDropdown 
                title={`${resolveText('Hello')}, ${name}`}
                className='me-2'
            >
                <NavDropdown.Item onClick={() => navigate(SharedUrls.MY_ACCOUNT)}>{resolveText("Account_ManageAccount")}</NavDropdown.Item>
            </NavDropdown> : null}
            <NavItem>
                <AsyncButton
                    className="me-3"
                    variant="danger"
                    onClick={logOut}
                    isExecuting={isLoggingOut}
                    activeText={resolveText('LogOut')}
                    executingText={resolveText('LogOut')}
                />
            </NavItem>
        </Nav>
    );

}