import { format } from 'date-fns';
import { Sex, MedicationDispensionState, MedicationPackageSizeType, SharedSubscriptionType, LegalEntityType, PractitionerRole, Currencies } from '../../localComponents/types/enums';
import { Models } from '../../localComponents/types/models';
import { ViewModels } from '../../localComponents/types/viewModels';
import { canResolveText, resolveText } from '../../sharedCommonComponents/helpers/Globalizer';
import { isPregnancyPersonId } from './PregnancyHelpers';
import { TimeRange } from '../types/frontendTypes';

export const formatDateTime = (date: Date) => {
    return format(date, 'yyyy-MM-dd HH:mm');
}
export const formatDate = (date: Date) => {
    return format(date, 'yyyy-MM-dd');
}
export const formatTime = (date: Date) => {
    return format(date, 'HH:mm');
}
export const formatTimeOfDay = (timeOfDay: Models.Scheduling.TimeOfDay) => {
    return `${String(timeOfDay.hour).padStart(2,'0')}:${String(timeOfDay.minute).padStart(2,'0')}`;
}
export const formatTimeRange = (timeRange: TimeRange) => {
    if(!timeRange || (!timeRange.start && !timeRange.end)) {
        throw new Error("Time range has neither start nor end");
    }
    if(!timeRange.end) {
        return `${formatDateTime(new Date(timeRange.start!))}`;
    }
    if(!timeRange.start) {
        return `${formatDateTime(new Date(timeRange.end!))}`;
    }
    return `${formatDate(new Date(timeRange.start!))} ${formatTime(new Date(timeRange.start!))} - ${formatTime(new Date(timeRange.end!))}`;
}
export const formatTimeslotTimeRange = (timeslot: Models.Scheduling.Timeslot) => {
    return `${formatDate(new Date(timeslot.startTime))} ${formatTime(new Date(timeslot.startTime))} - ${formatTime(new Date(timeslot.endTime))}`;
}
export const formatPersonName = (person: Models.Individuals.Person) => {
    if(!person) {
        return resolveText("UnknownPerson");
    }
    return `${person.firstName} ${person.lastName}`;
}
export const formatPerson = (person: Models.Individuals.Person | undefined) => {
    if(!person) {
        return resolveText("UnknownPerson");
    }
    const genderSymbol = person.sex === Sex.Male ? '♂'
        : person.sex === Sex.Female ? '♀'
        : '⚥';
    const isPregnancyProfile = isPregnancyPersonId(person.personId);
    if(isPregnancyProfile) {
        return `${person.firstName} ${person.lastName} (${genderSymbol})`;    
    }
    return `${person.firstName} ${person.lastName} (${person.personId}, ${genderSymbol})`;
}
export const formatMenschId = (menschId: string) => {
    return `웃ID: ${menschId}`;
}
export const formatPractitionerRole = (role: PractitionerRole) => resolveText(`PractitionerRole_${role}`);
export const formatPractitioner = (practitioner: Models.Individuals.Practitioner) => {
    return `${practitioner.name} (${formatPractitionerRole(practitioner.role)})`;
}

export const formatEncounter = (encounter: Models.Scheduling.Encounter) => {
    return `${formatDate(new Date(encounter.startTime))} - ${encounter.endTime ? formatDate(new Date(encounter.endTime)) : ''}`;
}
export const formatObservationValue = (observation: Models.DiagnosticTests.QuantitativeDiagnosticTestResult) => {
    if(observation.unit) {
        return `${observation.value.toPrecision(3)} ${observation.unit}`;
    }
    return observation.value;
}
export const formatObservation = (observation: Models.DiagnosticTests.QuantitativeDiagnosticTestResult) => {
    return `${formatClassificationReference(observation.code)}: ${formatObservationValue(observation)}`;
}
export const formatDrug = (drug: Models.Medication.Drug) => {
    return `${drug.productName}`;
}
export const formatReferenceRange = (testResult: Models.DiagnosticTests.QuantitativeDiagnosticTestResult) => {
    if(testResult.referenceRangeStart && testResult.referenceRangeEnd) {
        return `${testResult.referenceRangeStart}-${testResult.referenceRangeEnd}`;
    }
    if(testResult.referenceRangeStart) {
        return `>${testResult.referenceRangeStart}`;
    }
    if(testResult.referenceRangeEnd) {
        return `<${testResult.referenceRangeEnd}`;
    }
    return '';
}
export const formatDiagnosticTestCode = (testDefinition: Models.Services.DiagnosticTestService) => {
    return formatClassificationReference(testDefinition.code);
}
export const formatDiagnosticTestNameOfResult = (testResult: Models.DiagnosticTests.DiagnosticTestResult) => {
    return formatClassificationReference(testResult.code);
}
export const formatDiagnosisNameAndCode = (diagnosis: ViewModels.HealthRecordEntries.DiagnosisViewModel) => {
    return formatClassificationReference(diagnosis.code);
}
export const formatMedicalProcedureCodeAndName = (medicalProcedure: Models.Procedures.MedicalProcedure) => {
    return formatClassificationReference(medicalProcedure.code);
}
export const formatDispension = (dispension: Models.Medication.MedicationDispension) => {
    let str = resolveText(`MedicationDispensionState_${dispension.state}`);
    if(dispension.state === MedicationDispensionState.Dispensed) {
        str += ` - ${dispension.value.toPrecision(4)} ${dispension.unit}`;
    }
    return str;
}
export const formatContactPersonRole = (role: string) => {
    if(canResolveText(`ContactPersonRole_${role}`)) {
        return resolveText(`ContactPersonRole_${role}`);
    }
    return role;
}
export const formatClassificationReference = (reference: Models.ClassificationReference) => {
    const formattedClassification = resolveText(`ClassificationType_${reference.classification}`);
    const code = `${formattedClassification}: ${reference.conceptId}`;
    return `${reference.displayName} (${code})`;
}
export const formatSpecimen = (specimen: Models.DiagnosticTests.Specimen) => {
    return `${specimen.bodyStructure.displayName} @ ${formatDateTime(specimen.timestamp)}`;
}
export const formatAppointment = (appointment: ViewModels.AppointmentViewModel) => {
    return `${formatPerson(appointment.person)}: ${formatDateTime(new Date(appointment.startTime))}`;
}
export const formatDepartment = (department: ViewModels.DepartmentViewModel) => {
    return `${department.name} (${department.institution.name})`;
}
export const formatQuestionnaireAnswers = (questionnaireAnswers: ViewModels.HealthRecordEntries.QuestionnaireAnswersViewModel) => {
    let displayName = `${questionnaireAnswers.questionnaireTitle}`;
    if(questionnaireAnswers.repeatingSeriesId) {
        displayName += ` @ ${formatDateTime(new Date(questionnaireAnswers.creationTimestamp))}`;
    }
    return displayName;
}
export const formatMedicationPattern = (pattern: Models.Medication.MedicationSchedulePattern) => {
    return `${pattern.morning}-${pattern.noon}-${pattern.evening}-${pattern.night}`;
}
export const formatPackageSize = (packageSize: Models.Medication.MedicationPackageSize) => {
    switch(packageSize.type) {
        case MedicationPackageSizeType.Tablets:
            const tabletPackageSize = packageSize as Models.Medication.TabletMedicationPackageSize;
            return `${tabletPackageSize.pillCount} ${resolveText(`MedicationPackageSizeType_Tablets`)}`;
        case MedicationPackageSizeType.Volume:
            const volumePackageSize = packageSize as Models.Medication.VolumeMedicationPackageSize;
            return `${volumePackageSize.amount}${volumePackageSize.unit}`;
        case MedicationPackageSizeType.Freetext:
            const freetextPackageSize = packageSize as Models.Medication.FreetextMedicationPackageSize;
            return freetextPackageSize.text;
        default:
            throw new Error(`Unknown package size ${packageSize.type}`);
    }
}
export const formatAddressOneLine = (address: Models.Address) => {
    return `${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city}, ${address.country}`;
}
export const formatSubscription = (subscription: Models.Subscriptions.Subscription) => {
    switch(subscription.type) {
        case SharedSubscriptionType.Department:
            const departmentSubscriptionViewModel = subscription as ViewModels.Subscriptions.DepartmentSubscriptionViewModel;
            return departmentSubscriptionViewModel.departmentName;
        case SharedSubscriptionType.Institution:
            const institutionSubscriptionViewModel = subscription as ViewModels.Subscriptions.InstitutionSubscriptionViewModel;
            return institutionSubscriptionViewModel.institutionName;
        case SharedSubscriptionType.Patient:
            const patientSubscriptionViewModel = subscription as ViewModels.Subscriptions.PatientSubscriptionViewModel;
            return patientSubscriptionViewModel.patientName;
        case SharedSubscriptionType.Service:
            const serviceSubscriptionViewModel = subscription as ViewModels.Subscriptions.ServiceSubscriptionViewModel;
            return `${serviceSubscriptionViewModel.serviceName} (${serviceSubscriptionViewModel.departmentName})`;
        case SharedSubscriptionType.ServiceRequest:
            const serviceRequestSubscriptionViewModel = subscription as ViewModels.Subscriptions.ServiceRequestSubscriptionViewModel;
            return `${serviceRequestSubscriptionViewModel.serviceName} (ID: ${serviceRequestSubscriptionViewModel.requestId.substring(0, 6)})`;
        default:
            return `${subscription.id}`;
    }
}
export const formatLegalEntityReference = (legalEntityReference: Models.LegalEntityReference): string => {
    if(legalEntityReference.type === LegalEntityType.Unknown) {
        throw new Error("Unsupported legal entity reference of type Unknown");
    }
    if(legalEntityReference.type === LegalEntityType.Guest) {
        return resolveText("LegalEntityType_Guest");
    }
    return `${resolveText(`LegalEntityType_${legalEntityReference.type}`)} ${legalEntityReference.entityId}`;
}
export const formatMoney = (money: Models.Financial.Money) => {
    return `${money.value.toFixed(2)} ${formatCurrency(money.currency)}`;
}
export const formatCurrency = (currency: Currencies): string => {
    switch(currency) {
        case Currencies.EUR:
            return '€';
        case Currencies.USD:
            return '$';
        default:
            return currency;
    }
}
export const formatAssignee = (assignee?: ViewModels.AssigneeViewModel) => {
    if(!assignee) {
        return undefined;
    }
    return assignee.displayName;
}
export const formatGestationalAge = (gestationalAge?: Models.ObGyn.GestationalAge) => {
    if(!gestationalAge) {
        return null;
    }
    return `${gestationalAge.weeks}+${gestationalAge.days}`;
}