import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { ShehrdUrls } from "../../navigation/Urls";

interface PublicHealthAgencyMenuProps {}

export const PublicHealthAgencyMenu = (props: PublicHealthAgencyMenuProps) => {

    const navigate = useNavigate();

    return (
    <Nav>
        <Nav.Item>
            <Nav.Link onClick={() => navigate(ShehrdUrls.PUBLICHEALTHAGENCY_SETTINGS)}>{resolveText("PublicHealthAgency")}</Nav.Link>
        </Nav.Item>
        <Nav.Item>
            <Nav.Link onClick={() => navigate(ShehrdUrls.EPIDEMIOLOGY_DASHBOARD)}>{resolveText("Epidemiology")}</Nav.Link>
        </Nav.Item>
    </Nav>);

}