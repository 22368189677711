import { Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { SharedUrls } from "../../../sharedHealthComponents/navigation/Urls";

interface PharmacyMenuProps {}

export const PharmacyMenu = (props: PharmacyMenuProps) => {

    const navigate = useNavigate();
    return (
        <Nav>
            <NavDropdown title={resolveText("Menu_Pharmacy")}>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.CREATE_PHARMACY_ORDER)}>{resolveText("Menu_NewPharmacyOrder")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.PHARMACY_ORDERS)}>{resolveText("Menu_PharmacyOrders")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.DRUGS)}>{resolveText("Menu_Drugs")}</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );

}
export default PharmacyMenu;