import { AccountType, LegalEntityType } from "../types/enums";
import { Models } from "../types/models";
import { ViewModels } from "../types/viewModels";

export const getPractitioner = (user: ViewModels.IUserViewModel | undefined) => {
    if(!user) {
        return undefined;
    }
    switch(user.accountType) {
        case AccountType.HealthProfessional:
        case AccountType.Researcher:
        case AccountType.Pharmacy:
        case AccountType.PublicHealthAgency:
        case AccountType.Admin:
            const professionalUserViewModel = user as ViewModels.ProfessionalUserViewModel;
            return professionalUserViewModel.professionalProfile.id;
        default:
            return undefined;
    }
}
export const getPerson = (user: ViewModels.IUserViewModel | undefined) => {
    if(!user) {
        return undefined;
    }
    switch(user.accountType) {
        case AccountType.Sharer:
        case AccountType.HealthProfessional:
        case AccountType.Researcher:
        case AccountType.Pharmacy:
        case AccountType.Admin:
            const personUserViewModel = user as ViewModels.PersonUserViewModel;
            return personUserViewModel.profileData;
        default:
            return undefined;
    }
}
export const getLegalEntityReference = (user: ViewModels.IUserViewModel | undefined): Models.LegalEntityReference | undefined => {
    if(!user) {
        return undefined;
    }
    switch(user.accountType) {
        case AccountType.HealthProfessional:
        case AccountType.Researcher:
        case AccountType.Pharmacy:
        case AccountType.PublicHealthAgency:
        case AccountType.Admin:
            const professionalUserViewModel = user as ViewModels.ProfessionalUserViewModel;
            return {
                type: LegalEntityType.Practitioner,
                entityId: professionalUserViewModel.professionalProfile.id
            };
        case AccountType.Sharer:
            const personUserViewModel = user as ViewModels.PersonUserViewModel;
            return {
                type: LegalEntityType.Person,
                entityId: personUserViewModel.profileData.id
            };
        case AccountType.EmergencyGuest:
            const guestUserViewModel = user as ViewModels.GuestViewModel;
            return {
                type: LegalEntityType.Guest,
                entityId: guestUserViewModel.guestId
            };
        default:
            return undefined;
    }
}