import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { OrderDirection } from "../../../localComponents/types/enums";
import { Models } from "../../../localComponents/types/models";
import { last } from "../../../sharedCommonComponents/helpers/CollectionHelpers";
import { QueryParameter } from "../../../sharedCommonComponents/types/frontendTypes";
import { HealthRecordEventFilter } from "../../types/frontendTypes";
import { PersonDataRemoteState, RemoteState } from "../../types/reduxInterfaces";
import { FilterComparer } from "../../types/reduxTypes";


export const defaultQueryBuilder = <
    ItemType extends Models.IId<string>,
    FilterType
>(
    rootState: RootState, 
    sliceState: RemoteState<ItemType,FilterType>,
    filterComparer: FilterComparer<FilterType>): QueryParameter[] => {
    const queryParams: QueryParameter[] = [
        { key: 'count', value: '20'}
    ];
    const filter = sliceState.filter;
    const lastUsedFilter = sliceState.lastUsedFilter;
    const filterHasChanged = !filterComparer(filter, lastUsedFilter);
    if(!filterHasChanged) {
        const currentItems = sliceState.items;
        if(currentItems.length > 0) {
            const lastItem = last(currentItems)!;
            queryParams.push({ key: 'lastFetchedId', value: lastItem.id });
        }
    }
    return queryParams;
}
export const healthRecordEntryQueryBuilder = <
    ItemType extends Models.IHealthRecordEntry<string>,
    FilterType extends HealthRecordEventFilter
>(
    rootState: RootState, 
    sliceState: PersonDataRemoteState<ItemType,FilterType>,
    filterComparer: FilterComparer<FilterType>): QueryParameter[] => {
    const queryParams: QueryParameter[] = [
        ...defaultQueryBuilder(rootState, sliceState, filterComparer),
        { key: 'orderBy', value: 'time' },
        { key: 'orderDirection', value: OrderDirection.Descending }
    ];

    const filter = sliceState.filter;
    if(filter) {
        if(filter.timeRange) {
            if(filter.timeRange.start) {
                queryParams.push({ key: 'timeRangeStart', value: filter.timeRange.start as any});
            }
            if(filter.timeRange.end) {
                queryParams.push({ key: 'timeRangeEnd', value: filter.timeRange.end as any });
            }
        }
    }
    const lastUsedFilter = sliceState.lastUsedFilter;
    const filterHasChanged = !filterComparer(filter, lastUsedFilter);
    if(!filterHasChanged) {
        const currentItems = sliceState.items;
        if(currentItems.length > 0) {
            const lastItem = last(currentItems)!;
            queryParams.push({ key: 'lastFetchedTimestamp', value: lastItem.timestamp as any });
        }
    }
    // Apply query parameter builder last to allow overwriting above default value
    //Object.assign(queryParams, queryParameterBuilder(state));
    return queryParams;
}