import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../../../localComponents/redux/store/healthRecordStore"
import { ViewModels } from "../../../localComponents/types/viewModels"
import { HealthRecordEventFilter } from "../../types/frontendTypes"
import { PersonDataRemoteState } from "../../types/reduxInterfaces"
import { Models } from "../../../localComponents/types/models"
import { createDefaultGenericItemSelectors } from "./GenericSliceSelectors"

export const createPersonDataSelectors = <
    ItemType extends (Models.IId<string> & Models.IPersonData),
    FilterType
>(
    sliceStateSelector: (state: RootState) => PersonDataRemoteState<ItemType,FilterType>,
) => ({
    ...createDefaultGenericItemSelectors(sliceStateSelector),
    createSelectForPerson: () => createSelector(
        (state: RootState) => sliceStateSelector(state).items,
        (_: RootState, args: { personId: string | undefined }) => args.personId,
        (items, personId) => personId ? items.filter(x => x.personId === personId) : []
    )
})
export const createHealthRecordEntrySelectors = <
    ItemType extends ViewModels.HealthRecordEntries.IHealthRecordEntryViewModelUnknownType,
    FilterType extends HealthRecordEventFilter
>(
    sliceStateSelector: (state: RootState) => PersonDataRemoteState<ItemType,FilterType>
) => ({
    ...createPersonDataSelectors(sliceStateSelector),
});