import { createVerb, DataTypeNames, editVerb, UrlIdPlaceholders } from "../../sharedHealthComponents/navigation/Urls";

export const ChecklistUrls = {
    CHECKLISTS: `/${DataTypeNames.CHECKLISTS}`,
    CREATE_CHECKLIST: `/${createVerb}/${DataTypeNames.CHECKLIST}`,
    EDIT_CHECKLIST: `/${editVerb}/${DataTypeNames.CHECKLIST}/${UrlIdPlaceholders.ID}`,
    VIEW_CHECKLIST: `/${DataTypeNames.CHECKLIST}/${UrlIdPlaceholders.CHECKLIST}`,
    ANSWER_CHECKLIST: `/${DataTypeNames.CHECKLIST}/${UrlIdPlaceholders.CHECKLIST}/${DataTypeNames.CHECKLISTANSWER}`,
    OPEN_ANSWER: `/${DataTypeNames.CHECKLIST}/${UrlIdPlaceholders.CHECKLIST}/${DataTypeNames.CHECKLISTANSWER}/${UrlIdPlaceholders.CHECKLISTANSWER}`,
    ANSWERS: `/${DataTypeNames.CHECKLIST}/${UrlIdPlaceholders.CHECKLIST}/${DataTypeNames.CHECKLISTANSWERS}`
};