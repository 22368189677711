import { lazy } from 'react';
import { ChecklistRouteDefinition } from '../types/frontendTypes';
import { ChecklistUrls } from './Urls';

const ChecklistsListPage = lazy(() => import('../pages/ChecklistsListPage'));
const AnswerChecklistPage = lazy(() => import('../pages/AnswerChecklistPage'));
const CreateEditChecklistPage = lazy(() => import('../pages/CreateEditChecklistPage'));
const ChecklistAnswerListPage = lazy(() => import('../pages/ChecklistAnswerListPage'));

export const ChecklistRoutes = () => {

    const routes: ChecklistRouteDefinition[] = [
        { path: ChecklistUrls.CHECKLISTS, element: <ChecklistsListPage /> },
        { path: ChecklistUrls.CREATE_CHECKLIST, element: <CreateEditChecklistPage /> },
        { path: ChecklistUrls.EDIT_CHECKLIST, element: <CreateEditChecklistPage /> },
        { path: ChecklistUrls.VIEW_CHECKLIST, element: <AnswerChecklistPage /> },
        { path: ChecklistUrls.ANSWER_CHECKLIST, element: <AnswerChecklistPage /> },
        { path: ChecklistUrls.OPEN_ANSWER, element: <AnswerChecklistPage /> },
        { path: ChecklistUrls.ANSWERS, element: <ChecklistAnswerListPage /> },
    ];

    return routes;
}
export default ChecklistRoutes;