import { HealthRecordEntryType } from "../../localComponents/types/enums";

export const createVerb = "create";
export const editVerb = "edit";
export const printVerb = "print";
export const deployVerb = "deploy";
export const UrlIdPlaceholders = {
    ID: ':id',
    INSTITUTION: ':institutionId',
    DEPARTMENT: ':departmentId',
    PERSON: ':personId',
    PRACTITIONER: ':practitionerId',
    ENCOUNTER: ':encounterId',
    CHECKLIST: ':checklistId',
    CHECKLISTANSWER: ':answerId',
    CHAT: ':chatId',
    SERVICE: ':serviceId',
    SERVICEPACKAGE: ':servicePackageId',
};
export const DataTypeNames = {
    INSTITUTIONS: 'institutions',
    INSTITUTION: 'institution',

    DEPARTMENTS: 'departments',
    DEPARTMENT: 'department',

    PERSONS: 'persons',
    PERSON: 'person',

    PRACTITIONERS: 'practitioners',
    PRACTITIONER: 'practitioner',

    APPOINTMENTS: 'appointments',
    APPOINTMENT: 'appointment',

    ENCOUNTERS: 'encounters',
    ENCOUNTER: 'encounter',

    DRUGS: 'drugs',
    DRUG: 'drug',

    QUESTIONNAIRES: 'questionnaires',
    QUESTIONNAIRE: 'questionnaire',

    CHECKLISTS: 'checklists',
    CHECKLIST: 'checklist',

    CHECKLISTANSWER: 'answer',
    CHECKLISTANSWERS: 'answers',

    LABTESTPACKAGES: 'labtestpackages',
    LABTESTPACKAGE: 'labtestpackage',

    EQUIPMENTMEASUREMENTS: 'equipmentmeasurements',
    EQUIPMENTMEASUREMENT: 'equipmentmeasurement',

    SCHEDULE: 'schedule',

    PRINTTEMPLATES: 'printtemplates',
    PRINTTEMPLATE: 'printtemplate',

    TASKS: 'tasks',
    TASK: 'task',

    TASKPACAKGES: 'taskpackages',
    TASKPACAKGE: 'taskpackage',

    PHARMACYORDERS: 'pharmacyorders',
    PHARMACYORDER: 'pharmacyorder',

    SERVICES: 'services',
    SERVICE: 'service',

    SERVICEPACKAGES: 'servicepackages',
    SERVICEPACKAGE: 'servicepackage',

    SERVICEREQUESTS: 'servicerequests',
    SERVICEREQUEST: 'servicerequest',
};
export const ToolsUrls = {
    CPR: '/tools/cpr',
    MY_CHATS: '/tools/chats',
    NEW_CHAT: '/tools/chat',
    OPEN_CHAT: `/tools/chat/${UrlIdPlaceholders.CHAT}`,
    JOIN_CHAT: `/tools/chat/${UrlIdPlaceholders.CHAT}/join`
};
export const HealthRecordUrls = {
    HEALTHRECORD_BASEURL: `/healthrecord/${UrlIdPlaceholders.PERSON}`,
    TIMELINE: 'timeline',
    PRINT_HEALTHRECORD: printVerb,
    EDIT_PERSON: `/${editVerb}/${DataTypeNames.PERSON}/${UrlIdPlaceholders.PERSON}`,

    CREATE_DIAGNOSIS: `${createVerb}/${HealthRecordEntryType.Diagnosis}`,
    EDIT_DIAGNOSIS: `${editVerb}/${HealthRecordEntryType.Diagnosis}/:id`,
    CREATE_ALLERGY: `${createVerb}/${HealthRecordEntryType.Allergy}`,
    EDIT_ALLERGY: `${editVerb}/${HealthRecordEntryType.Allergy}/:id`,
    CREATE_MEDICALALERT: `${createVerb}/${HealthRecordEntryType.MedicalAlert}`,
    EDIT_MEDICALALERT: `${editVerb}/${HealthRecordEntryType.MedicalAlert}/:id`,
    CREATE_WORKUP: `${createVerb}/workup`,
    EDIT_WORKUP: `${editVerb}/workup/:id`,
    CREATE_SURGERY: `${createVerb}/surgery`,
    EDIT_SURGERY: `${editVerb}/surgery/:id`,
    CREATE_DOCUMENT: `${createVerb}/${HealthRecordEntryType.Document}`,
    EDIT_DOCUMENT: `${editVerb}/${HealthRecordEntryType.Document}/:id`,
    CREATE_EQUIPMENT: `${createVerb}/${HealthRecordEntryType.Equipment}`,
    EDIT_EQUIPMENT: `${editVerb}/${HealthRecordEntryType.Equipment}/:id`,
    MEDICATIONS: 'medications',
    CREATE_MEDICATIONDISPENSION: `${createVerb}/${HealthRecordEntryType.MedicationDispension}`,
    EDIT_MEDICATIONDISPENSION: `${editVerb}/${HealthRecordEntryType.MedicationDispension}/:id`,
    CREATE_IMMUNIZATION: `${createVerb}/${HealthRecordEntryType.Immunization}`,
    EDIT_IMMUNIZATION: `${editVerb}/${HealthRecordEntryType.Immunization}/:id`,
    CREATE_MEDICATIONSCHEDULE: `${createVerb}/${HealthRecordEntryType.MedicationSchedule}`,
    EDIT_MEDICATIONSCHEDULE: `${editVerb}/${HealthRecordEntryType.MedicationSchedule}/:id`,
    CREATE_NOTE: `${createVerb}/${HealthRecordEntryType.Note}`,
    EDIT_NOTE: `${editVerb}/${HealthRecordEntryType.Note}/:id`,
    VIEW_NOTE: `${HealthRecordEntryType.Note}/:id`,
    COMPARE_NOTES: 'notes/compare',
    CREATE_OBSERVATION: `${createVerb}/observation`,
    EDIT_OBSERVATION: `${editVerb}/observation/:id`,
    CREATE_PREGNANCY: `${createVerb}/${HealthRecordEntryType.Pregnancy}`,
    EDIT_PREGNANCY: `${editVerb}/${HealthRecordEntryType.Pregnancy}/:id`,
    CREATE_PROCEDURE: `${createVerb}/${HealthRecordEntryType.Procedure}`,
    EDIT_PROCEDURE: `${editVerb}/${HealthRecordEntryType.Procedure}/:id`,
    QUESTIONS: 'questions',
    CREATE_QUESTIONNAIRE: `add/${HealthRecordEntryType.Questionnaire}`,
    ANSWER_QUESTIONNAIRE: `${HealthRecordEntryType.Questionnaire}/:questionnaireId/answer`,
    EDIT_QUESTIONNAIREANSWER: `${HealthRecordEntryType.Questionnaire}/:questionnaireId/answer/:answerId`,
    CREATE_QUESTIONNAIRESERIES: `${HealthRecordEntryType.Questionnaire}/:questionnaireId/repetition`,
    EDIT_QUESTIONNAIRESERIES: `${HealthRecordEntryType.Questionnaire}/:questionnaireId/repetition/:seriesId`,
    CREATE_SPECIMEN: `${createVerb}/${HealthRecordEntryType.Specimen}`,
    EDIT_SPECIMEN: `${editVerb}/${HealthRecordEntryType.Specimen}/:id`,
    CREATE_TESTRESULT: `${createVerb}/${HealthRecordEntryType.TestResult}`,
    EDIT_TESTRESULT: `${editVerb}/${HealthRecordEntryType.TestResult}/:id`,
    CREATE_OPINION: `${createVerb}/opinion/for/:entryType/:entryId`,
    EDIT_OPINION: `${editVerb}/opinion/:opinionId`,

    CREATE_ENCOUNTER: `${createVerb}/${HealthRecordEntryType.Encounter}`,
    EDIT_ENCOUNTER: `${editVerb}/${HealthRecordEntryType.Encounter}/${UrlIdPlaceholders.ID}`,
    CREATE_PRESCRIPTION: `${createVerb}/${HealthRecordEntryType.Prescription}`,
    EDIT_PRESCRIPTION: `${editVerb}/${HealthRecordEntryType.Prescription}/${UrlIdPlaceholders.ID}`,
    PRINT_PRESCRIPTION: `/${printVerb}/${HealthRecordEntryType.Prescription}/${UrlIdPlaceholders.ID}`,
    NEW_CPR: 'cpr',
    OPEN_CPR: `cpr/${UrlIdPlaceholders.ID}`,
    PRINT_SPECIMEN:  `/${printVerb}/specimen/${UrlIdPlaceholders.ID}`,
    ADD_TASK: 'add/task',
    UPLOAD_IMAGING: 'upload/imaging',
    OPEN_IMAGINGSTUDY: 'imaging/:dicomStudyId',
    UPLOAD_GENOME: 'upload/genome',
    OPEN_GENOMEEXPLORER: 'genome',
};
export const buildHealthRecordUrl = (personId: string, subUrl?: string) => {
    const baseUrl = HealthRecordUrls.HEALTHRECORD_BASEURL.replace(UrlIdPlaceholders.PERSON, personId);
    if(subUrl) {
        if(subUrl.startsWith('/')) {
            return baseUrl + subUrl;
        }
        return baseUrl + "/" + subUrl;
    }
    return baseUrl;
}
export const SharedUrls = {
    PRIVACY_STATEMENT: '/privacy',
    LOGIN: '/login',
    MY_ACCOUNT: '/myaccount',

    INSTITUTIONS: `/${DataTypeNames.INSTITUTIONS}`,
    CREATE_INSTITUTION: `/${createVerb}/${DataTypeNames.INSTITUTION}`,
    EDIT_INSTITUTION: `/${editVerb}/${DataTypeNames.INSTITUTION}/${UrlIdPlaceholders.ID}`,

    DEPARTMENTS: `/${DataTypeNames.DEPARTMENTS}`,
    CREATE_DEPARTMENT: `/${createVerb}/${DataTypeNames.DEPARTMENT}`,
    INSTITUTION_CREATE_DEPARTMENT: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/${createVerb}/${DataTypeNames.DEPARTMENT}`,
    INSTITUTION_EDIT_DEPARTMENT: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/${editVerb}/${DataTypeNames.DEPARTMENT}/${UrlIdPlaceholders.ID}`,
    EDIT_DEPARTMENT: `/${editVerb}/${DataTypeNames.DEPARTMENT}/${UrlIdPlaceholders.ID}`,
    OPEN_DEPARTMENT: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.ID}`,

    CREATE_PERSON: `/${createVerb}/${DataTypeNames.PERSON}`,
    OPEN_PRACTITIONER: `/${DataTypeNames.PRACTITIONERS}/${UrlIdPlaceholders.ID}`,

    DRUGS: `/${DataTypeNames.DRUGS}`,
    CREATE_DRUG: `/${createVerb}/${DataTypeNames.DRUG}`,
    EDIT_DRUG: `/${editVerb}/${DataTypeNames.DRUG}/${UrlIdPlaceholders.ID}`,

    TODOLIST: '/todoList',
    CREATE_TASK: `/${createVerb}/${DataTypeNames.TASK}`,
    EDIT_TASK: `/${editVerb}/${DataTypeNames.TASK}/${UrlIdPlaceholders.ID}`,
    OPEN_TASK: `/${DataTypeNames.TASK}/${UrlIdPlaceholders.ID}`,
    PRINT_TODOLIST: `/${printVerb}/${DataTypeNames.TASKS}`,

    TASK_PACKAGES: `/${DataTypeNames.TASKPACAKGES}`,
    CREATE_TASKPACKAGE: `/${createVerb}/${DataTypeNames.TASKPACAKGE}`,
    EDIT_TASKPACKAGE: `/${editVerb}/${DataTypeNames.TASKPACAKGE}/${UrlIdPlaceholders.ID}`,
    DEPLOY_TASKPACKAGE: `/${deployVerb}/${DataTypeNames.TASKPACAKGE}`,
    TASKPACKAGE_DEPLOY: `/${DataTypeNames.TASKPACAKGES}/${UrlIdPlaceholders.ID}/${deployVerb}`,

    QUESTIONNAIRES: `/${DataTypeNames.QUESTIONNAIRES}`,
    CREATE_QUESTIONNAIRE: `/${createVerb}/${DataTypeNames.QUESTIONNAIRE}`,
    EDIT_QUESTIONNAIRE: `/${editVerb}/${DataTypeNames.QUESTIONNAIRE}/${UrlIdPlaceholders.ID}`,

    LABTEST_PACKAGES: `/${DataTypeNames.LABTESTPACKAGES}`,
    EXPORT_PATIENTDATA: '/export',

    EQUIPMENT_MEASUREMENTS: `/${DataTypeNames.EQUIPMENTMEASUREMENTS}`,
    CREATE_EQUIPMENT_MEASUREMENT: `/${createVerb}/${DataTypeNames.EQUIPMENTMEASUREMENT}`,
    EDIT_EQUIPMENT_MEASUREMENT: `/${editVerb}/${DataTypeNames.EQUIPMENTMEASUREMENT}/${UrlIdPlaceholders.ID}`,

    FEEDBACK: '/feedback',

    INSTITUTION_EDIT_SCHEDULE: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/${DataTypeNames.SCHEDULE}`,
    INSTITUTION_DEPARTMENT_EDIT_SCHEDULE: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${DataTypeNames.SCHEDULE}`,

    DEPARTMENT_CREATE_ENCOUNTER: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${createVerb}/${DataTypeNames.ENCOUNTER}`,

    ENCOUNTERS: `/${DataTypeNames.ENCOUNTERS}`,
    APPOINTMENTS: `/${DataTypeNames.APPOINTMENTS}`,
    CREATE_APPOINTMENT: `/${createVerb}/${DataTypeNames.APPOINTMENT}`,
    INSTITUTION_CREATE_APPOINTMENT: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/${createVerb}/${DataTypeNames.APPOINTMENT}`,
    INSTITUTION_DEPARTMENT_CREATE_APPOINTMENT: `/${DataTypeNames.INSTITUTIONS}/${UrlIdPlaceholders.INSTITUTION}/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${createVerb}/${DataTypeNames.APPOINTMENT}`,
    DEPARTMENT_CREATE_APPOINTMENT: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${createVerb}/${DataTypeNames.APPOINTMENT}`,
    EDIT_APPOINTMENT: `/${editVerb}/${DataTypeNames.APPOINTMENT}/${UrlIdPlaceholders.ID}`,
    PRINT_APPOINTMENT: `/${printVerb}/${DataTypeNames.APPOINTMENT}/${UrlIdPlaceholders.ID}`,

    PRINT_TEMPLATES: `/${DataTypeNames.PRINTTEMPLATES}`,
    CREATE_PRINTTEMPLATE: `/${createVerb}/${DataTypeNames.PRINTTEMPLATE}`,
    EDIT_PRINTTEMPLATE: `/${editVerb}/${DataTypeNames.PRINTTEMPLATE}/${UrlIdPlaceholders.ID}`,

    PHARMACY_ORDERS: `/${DataTypeNames.PHARMACYORDERS}`,
    CREATE_PHARMACY_ORDER: `/${createVerb}/${DataTypeNames.PHARMACYORDER}`,
    EDIT_PHARMACY_ORDER: `/${editVerb}/${DataTypeNames.PHARMACYORDER}/${UrlIdPlaceholders.ID}`,
    OPEN_PHARMACY_ORDER: `/${DataTypeNames.PHARMACYORDER}/${UrlIdPlaceholders.ID}`,

    ADVERSE_EVENTS: '/adverse-events',
    CREATE_ADVERSE_EVENT: `/${createVerb}/adverse-event`,
    EDIT_ADVERSE_EVENT: `/${editVerb}/adverse-event/${UrlIdPlaceholders.ID}`,

    SERVICES: `/${DataTypeNames.SERVICES}`,
    CREATE_SERVICE: `/${createVerb}/${DataTypeNames.SERVICE}`,
    EDIT_SERVICE: `/${editVerb}/${DataTypeNames.SERVICE}/${UrlIdPlaceholders.ID}`,
    OPEN_SERVICE: `/${DataTypeNames.SERVICE}/${UrlIdPlaceholders.ID}`,
    DEPARTMENT_SERVICES: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${DataTypeNames.SERVICES}`,
    DEPARTMENT_CREATE_SERVICE: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${createVerb}/${DataTypeNames.SERVICE}`,
    SERVICEPACKAGE_SERVICES: `/${DataTypeNames.SERVICEPACKAGES}/${UrlIdPlaceholders.SERVICEPACKAGE}/${DataTypeNames.SERVICES}`,

    SERVICEPACKAGES: `/${DataTypeNames.SERVICEPACKAGES}`,
    DEPARTMENT_SERVICEPACKAGES: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${DataTypeNames.SERVICEPACKAGES}`,
    CREATE_SERVICEPACKAGE: `/${createVerb}/${DataTypeNames.SERVICEPACKAGE}`,
    EDIT_SERVICEPACKAGE: `/${editVerb}/${DataTypeNames.SERVICEPACKAGE}/${UrlIdPlaceholders.ID}`,

    SERVICEREQUESTS: `/${DataTypeNames.SERVICEREQUESTS}`,
    SERVICE_SERVICEREQUESTS: `/${DataTypeNames.SERVICES}/${UrlIdPlaceholders.SERVICE}/${DataTypeNames.SERVICEREQUESTS}`,
    DEPARTMENT_SERVICEREQUESTS: `/${DataTypeNames.DEPARTMENTS}/${UrlIdPlaceholders.DEPARTMENT}/${DataTypeNames.SERVICEREQUESTS}`,
    OPEN_SERVICEREQUEST: `/${DataTypeNames.SERVICEREQUEST}/${UrlIdPlaceholders.ID}`,
    CREATE_SERVICEREQUEST: `/${createVerb}/${DataTypeNames.SERVICEREQUEST}`,
    SERVICE_CREATE_SERVICEREQUESTS: `/${DataTypeNames.SERVICES}/${UrlIdPlaceholders.SERVICE}/${createVerb}/${DataTypeNames.SERVICEREQUEST}`,
}