import { CaseReducerActions, SliceCaseReducers } from "@reduxjs/toolkit";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { Models } from "../../../localComponents/types/models";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { RemoteState, GenericItemReducers, LoadItemArgs } from "../../types/reduxInterfaces";
import { FilterComparer, QueryParameterBuilder } from "../../types/reduxTypes";
import { loadItemActionBuilder, postActionBuilder, deleteActionBuilder, loadItemsActionBuilder, loadItemIfNotYetLoadedActionBuilder } from "./ActionCreatorBuilder";

export const createRestApiActions = <
    ItemType extends Models.IId<string>,
    FilterType
>(
    controllerName: string,
    actions: CaseReducerActions<SliceCaseReducers<RemoteState<ItemType,FilterType>>, string>,
    sliceStateSelector: (state: RootState) => RemoteState<ItemType,FilterType>,
    queryParametersBuilder: QueryParameterBuilder<ItemType,FilterType>,
    filterComparer: FilterComparer<FilterType>
) => {
    const typedActions = actions as any as GenericItemReducers<ItemType,FilterType>;
    return {
        loadItems: loadItemsActionBuilder(
            _ => `api/${controllerName}`,
            queryParametersBuilder,
            filterComparer,
            () => resolveText("GenericItems_CouldNotLoad"),
            typedActions.setIsLoading,
            typedActions.setItems,
            typedActions.addOrUpdateItems,
            typedActions.setHasMoreItems,
            typedActions.setLastUsedFilter,
            sliceStateSelector
        ),
        loadItem: loadItemActionBuilder(
            (args: LoadItemArgs) => `api/${controllerName}/${args.itemId}`,
            _ => ({}),
            () => resolveText("GenericItem_CouldNotLoad"),
            typedActions.setIsLoading,
            typedActions.addOrUpdateItem
        ),
        loadItemIfNotLoadedYet: loadItemIfNotYetLoadedActionBuilder(
            (args: LoadItemArgs) => `api/${controllerName}/${args.itemId}`,
            _ => ({}),
            () => resolveText("GenericItem_CouldNotLoad"),
            typedActions.setIsLoading,
            typedActions.addOrUpdateItem,
            sliceStateSelector
        ),
        addItem: postActionBuilder(
            () => `api/${controllerName}`, 
            () => resolveText("GenericItem_CouldNotStore"),
            typedActions.setIsSubmitting,
            (dispatch,response) => dispatch(typedActions.addOrUpdateItem(response))
        ),
        deleteItem: deleteActionBuilder(
            (itemId: string) => `api/${controllerName}/${itemId}`,
            () => resolveText("GenericItem_SuccessfullyDeleted"),
            () => resolveText("GenericItem_CouldNotDelete"),
            (dispatch, args) => dispatch(typedActions.removeItem(args))
        )
    }
}