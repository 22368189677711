import { NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { ShehrdUrls } from "../../navigation/Urls";
import { SharedUrls } from "../../../sharedHealthComponents/navigation/Urls";

interface AboutMenuProps {}

export const AboutMenu = (props: AboutMenuProps) => {

    const navigate = useNavigate();

    return (<NavDropdown title={resolveText("About")}>
        <NavDropdown.Item onClick={() => navigate(ShehrdUrls.FAQ)}>{resolveText("FAQ")}</NavDropdown.Item>
        <NavDropdown.Item onClick={() => navigate(ShehrdUrls.ABOUT_EHDS)}>European Health Data Space</NavDropdown.Item>
        <NavDropdown.Item onClick={() => navigate(SharedUrls.PRIVACY_STATEMENT)}>{resolveText("Privacy")}</NavDropdown.Item>
        <NavDropdown.Item onClick={() => navigate(ShehrdUrls.TERMS_OF_SERVICE)}>{resolveText("TermsAndConditions")}</NavDropdown.Item>
        <NavDropdown.Item onClick={() => navigate(ShehrdUrls.CONTACT)}>{resolveText("Contact")}</NavDropdown.Item>
    </NavDropdown>);

}