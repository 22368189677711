import { lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteDefinition } from "../../sharedCommonComponents/types/frontendTypes";
import { Models } from "../types/models";
import { ToolsUrls } from "./Urls";

const IsItBrokenHomePage = lazy(() => import("../pages/IsItBroken/IsItBrokenHomePage"));
const IsItBrokenCaseListPage = lazy(() => import("../pages/IsItBroken/IsItBrokenCaseListPage"));
const IsItBrokenAnswerPage = lazy(() => import("../pages/IsItBroken/IsItBrokenAnswerPage"));
const IsItBrokenActivationPage = lazy(() => import("../pages/IsItBroken/IsItBrokenActivationPage"));
const IsItBrokenGenerationPage = lazy(() => import("../pages/IsItBroken/IsItBrokenGenerationPage"));
const CprPage = lazy(() => import('../../sharedHealthComponents/pages/Patients/CprPage'));
const CreateTranslatedChatPage = lazy(() => import("../../sharedHealthComponents/pages/Chat/CreateTranslatedChatPage"));
const TranslatedChatPage = lazy(() => import("../../sharedHealthComponents/pages/Chat/TranslatedChatPage"));
const JoinTranslatedChatPage = lazy(() => import("../../sharedHealthComponents/pages/Chat/JoinTranslatedChatPage"));

export const ToolsRoutes = (
    features: Models.Configuration.FeatureSettings, 
    onNewAuthenticationResult: (authenticationResult: Models.AccessControl.AuthenticationResult) => void,
    onLogOut: () => Promise<void>) => {

    const routes: RouteDefinition[] = [
        { path: ToolsUrls.CPR, element: <CprPage />, usesCustomLayout: true },
        { path: ToolsUrls.NEW_CHAT, element: <CreateTranslatedChatPage onNewAuthenticationResult={onNewAuthenticationResult} />},
        { path: ToolsUrls.OPEN_CHAT, element: <TranslatedChatPage onLogOut={onLogOut} />},
        { path: ToolsUrls.JOIN_CHAT, element: <JoinTranslatedChatPage onNewAuthenticationResult={onNewAuthenticationResult} />},

        { path: ToolsUrls.ISITBROKEN, element: <IsItBrokenHomePage /> },
        { path: ToolsUrls.ISITBROKEN_CASES, element: <IsItBrokenCaseListPage /> },
        { path: ToolsUrls.ISITBROKEN_ANSWER, element: <IsItBrokenAnswerPage /> },
        { path: ToolsUrls.ISITBROKEN_ACTIVATE_CASE, element: <IsItBrokenActivationPage /> },
        { path: ToolsUrls.ISITBROKEN_GENERATE, element: <IsItBrokenGenerationPage /> },
    ];
    if(features.enableTaskList) {
        routes.push({ path: ToolsUrls.CHECKLISTS, element: <Navigate to='/checklists' /> });
    }
    return routes;

}
export default ToolsRoutes;