import { confirmAlert } from "react-confirm-alert";
import { ConditionClinicalStatusCodes, HealthRecordEntryType } from "../../localComponents/types/enums";
import { resolveText } from "../../sharedCommonComponents/helpers/Globalizer";
import { AppDispatch } from "../../localComponents/redux/store/healthRecordStore";
import { Models } from "../../localComponents/types/models";
import { compareAsc, compareDesc } from "date-fns";
import { last } from "../../sharedCommonComponents/helpers/CollectionHelpers";
import { noteActions } from "../redux/slices/notesSlice";
import { documentsActions } from "../redux/slices/documentsSlice";
import { diagnosesActions } from "../redux/slices/diagnosesSlice";
import { testResultsActions } from "../redux/slices/testResultsSlice";
import { allergiesActions } from "../redux/slices/allergiesSlice";
import { medicalProceduresActions } from "../redux/slices/medicalProceduresSlice";
import { healthRecordsSlice } from "../redux/slices/healthRecordsSlice";
import { pregnanciesActions } from "../redux/slices/pregnanciesSlice";
import { medicationDispensionsActions } from "../redux/slices/medicationDispensionsSlice";
import { isolationsActions } from "../redux/slices/isolationsSlice";
import { medicalAlertsActions } from "../redux/slices/medicalAlertsSlice";

export const getHealthRecordEntryTimestampAsDate = (entry: Models.IHealthRecordEntry<string>) => new Date(entry.timestamp);
export const unhideHealthRecordEntry = (dispatch: AppDispatch, entryType: HealthRecordEntryType, entryId: string) => {
    switch(entryType) {
        case HealthRecordEntryType.Note:
            dispatch(noteActions.markItemAsSeen({ args: entryId }));
            break;
        case HealthRecordEntryType.Document:
            dispatch(documentsActions.markItemAsSeen({ args: entryId }));
            break;
        case HealthRecordEntryType.Diagnosis:
            dispatch(diagnosesActions.markItemAsSeen({ args: entryId }));
            break;
        case HealthRecordEntryType.Allergy:
            dispatch(allergiesActions.markItemAsSeen({ args: entryId }));
            break;
        case HealthRecordEntryType.Procedure:
            dispatch(medicalProceduresActions.markItemAsSeen({ args: entryId }));
            break;
        case HealthRecordEntryType.TestResult:
            dispatch(testResultsActions.markItemAsSeen({ args: entryId }));
            break;
        case HealthRecordEntryType.MedicationDispension:
        case HealthRecordEntryType.Infusion:
            dispatch(medicationDispensionsActions.markItemAsSeen({ args: entryId }));
            break;
        case HealthRecordEntryType.Pregnancy:
            dispatch(pregnanciesActions.markItemAsSeen({ args: entryId }));
            break;
        case HealthRecordEntryType.Isolation:
            dispatch(isolationsActions.markItemAsSeen({ args: entryId }));
            break;
        case HealthRecordEntryType.MedicalAlert:
            dispatch(medicalAlertsActions.markItemAsSeen({ args: entryId }));
            break;
        default:
            throw new Error(`Unhiding is not implemented for '${entryType}'`);
    }
    dispatch(healthRecordsSlice.actions.markItemAsSeen(entryId));
}
export const confirmUnhide = (callback: () => void) => {
    confirmAlert({
        title: resolveText('HealthRecordEntry_ConfirmUnhide_Title'),
        message: resolveText('HealthRecordEntry_ConfirmUnhide_Message'),
        closeOnClickOutside: true,
        buttons: [
            {
                label: resolveText("HealthRecordEntry_ConfirmUnhide_Yes"),
                onClick: callback
            },
            {
                label: resolveText("HealthRecordEntry_ConfirmUnhide_No"),
                onClick: () => {}
            }
        ]
    });
}

export const confirmVerified = (callback: () => void) => {
    confirmAlert({
        title: resolveText('HealthRecordEntry_ConfirmVerify_Title'),
        message: resolveText('HealthRecordEntry_ConfirmVerify_Message'),
        closeOnClickOutside: true,
        buttons: [
            {
                label: resolveText("HealthRecordEntry_ConfirmVerify_Yes"),
                onClick: callback
            },
            {
                label: resolveText("HealthRecordEntry_ConfirmVerify_No"),
                onClick: () => {}
            }
        ]
    });
}

export const sortByTimeAscending = <T extends Models.IHealthRecordEntry<string>>(entries: T[]): T[] => {
    return [...entries].sort((a,b) => compareAsc(new Date(a.timestamp), new Date(b.timestamp)));
}
export const sortByTimeDescending = <T extends Models.IHealthRecordEntry<string>>(entries: T[]): T[] => {
    return [...entries].sort((a,b) => compareDesc(new Date(a.timestamp), new Date(b.timestamp)));
}
export const lastStatus = (diagnosis: Models.Diagnoses.Diagnosis) => {
    return last(diagnosis.statusChanges)?.status ?? ConditionClinicalStatusCodes.Active;
}
export const getControllerName = (healthRecordEntryType: HealthRecordEntryType) => {
    switch(healthRecordEntryType) {
        case HealthRecordEntryType.Allergy:
            return 'allergies';
        case HealthRecordEntryType.Diagnosis:
            return 'diagnoses';
        case HealthRecordEntryType.Document:
            return 'documents';
        case HealthRecordEntryType.Encounter:
            return 'encounters';
        case HealthRecordEntryType.Equipment:
            return 'equipments';
        case HealthRecordEntryType.HealthProfessionalOpinion:
            return 'opinions';
        case HealthRecordEntryType.MedicationSchedule:
            return 'medicationSchedules';
        case HealthRecordEntryType.Immunization:
            return 'immunizations';
        case HealthRecordEntryType.MedicationDispension:
        case HealthRecordEntryType.Infusion:
            return 'medicationDispensions';
        case HealthRecordEntryType.Note:
            return 'notes';
        case HealthRecordEntryType.Procedure:
            return 'medicalProcedures';
        case HealthRecordEntryType.Question:
            return 'questionAnswers';
        case HealthRecordEntryType.Questionnaire:
            return 'questionnaireAnswers';
        case HealthRecordEntryType.Specimen:
            return 'specimens';
        case HealthRecordEntryType.TestResult:
            return 'testResults';
        case HealthRecordEntryType.MedicalAlert:
            return 'medicalAlerts';
        case HealthRecordEntryType.Prescription:
            return 'medicationRequests';
        case HealthRecordEntryType.Isolation:
            return 'isolations';
        case HealthRecordEntryType.Pregnancy:
            return 'pregnancies';
        case HealthRecordEntryType.Process:
            return 'treatmentProcesses';
        case HealthRecordEntryType.FamilyTree:
            return 'familytrees';
        case HealthRecordEntryType.HealthRecordSummary:
            return 'healthRecordSummary';
        default:
            throw new Error(`getControllerName not implemented for ${healthRecordEntryType}`);
    }
}
export const getIconForHealthRecordEntryType = (entryType: HealthRecordEntryType) => {
    switch(entryType) {
        case HealthRecordEntryType.Allergy:
            return "fa-bolt";
        case HealthRecordEntryType.Diagnosis:
            return "fa-exclamation-circle";
        case HealthRecordEntryType.Document:
            return "fa-file";
        case HealthRecordEntryType.Encounter:
            return "fa-calendar";
        case HealthRecordEntryType.Equipment:
            return "fa-wheelchair-alt";
        case HealthRecordEntryType.HealthProfessionalOpinion:
            return "fa-comment";
        case HealthRecordEntryType.Immunization:
            return "fa-eyedropper";
        case HealthRecordEntryType.MedicationDispension:
        case HealthRecordEntryType.Infusion:
        case HealthRecordEntryType.MedicationSchedule:
        case HealthRecordEntryType.Prescription:
            return "fa-medkit";
        case HealthRecordEntryType.Note:
            return "fa-file-text";
        case HealthRecordEntryType.Pregnancy:
            return "fa-odnoklassniki";
        case HealthRecordEntryType.Procedure:
            return "fa-american-sign-language-interpreting";
        case HealthRecordEntryType.Question:
            return 'fa-question';
        case HealthRecordEntryType.Questionnaire:
            return "fa-pencil-square-o";
        case HealthRecordEntryType.Specimen:
            return "fa-eyedropper";
        case HealthRecordEntryType.TestResult:
            return "fa-flask";
        case HealthRecordEntryType.Isolation:
            return "fa-fort-awesome";
        case HealthRecordEntryType.Process:
            return "fa-road";
        case HealthRecordEntryType.MedicalAlert:
            return "fa-exclamation-triangle";
        case HealthRecordEntryType.FamilyTree:
            return "fa-sitemap";
        case HealthRecordEntryType.HealthRecordSummary:
            return "fa-exclamation-triangle";
        default:
            throw new Error(`getIconForHealthRecordEntryType not implemented for ${entryType}`);
    }
}
