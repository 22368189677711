import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../localComponents/redux/store/healthRecordStore";
import { RemoteState } from "../../types/reduxInterfaces";
import { Models } from "../../../localComponents/types/models";

export const createSelectHasFilterChanged = <TItem extends Models.IId<string>, TFilter extends unknown>(
    sliceSelector: (state: RootState) => RemoteState<TItem,TFilter>,
    filterComparer: (f1: TFilter | undefined, f2: TFilter | undefined) => boolean
    ) => () => createSelector(
        (state: RootState) => sliceSelector(state).filter,
        (state: RootState) => sliceSelector(state).lastUsedFilter,
        (filter, lastUsedFilter) => filterComparer(filter, lastUsedFilter)
    );
export const createDefaultGenericItemSelectors = <
    TItem extends Models.IId<string>, 
    TFilter extends unknown
>(
    sliceSelector: (state: RootState) => RemoteState<TItem, TFilter>
) => ({
    getById: () => createSelector(
        (state: RootState) => sliceSelector(state).items,
        (_: RootState, args: { id?: string }) => args.id,
        (items, id) => id ? items.find(x => x.id === id) : undefined
    ),
});