import { CaseReducerActions, SliceCaseReducers } from "@reduxjs/toolkit"
import { RootState } from "../../../localComponents/redux/store/healthRecordStore"
import { Models } from "../../../localComponents/types/models"
import { ViewModels } from "../../../localComponents/types/viewModels"
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer"
import { HealthRecordEventFilter } from "../../types/frontendTypes"
import { FilterComparer, QueryParameterBuilder } from "../../types/reduxTypes"
import { PersonDataRemoteState, HealthRecordEntryReducers, PersonDataReducers, LoadItemArgs } from "../../types/reduxInterfaces"
import { deleteActionBuilder, loadItemActionBuilder, loadItemIfNotYetLoadedActionBuilder, loadPersonDataActionBuilder, postActionBuilder } from "./ActionCreatorBuilder"
import { healthRecordsSlice } from "../slices/healthRecordsSlice"
import { HealthRecordEntryType } from "../../../localComponents/types/enums"


export const createPersonDataApiActions = <ItemType extends (Models.IId<string> & Models.IPersonData),FilterType>(
    controllerName: string,
    actions: CaseReducerActions<SliceCaseReducers<PersonDataRemoteState<ItemType,FilterType>>, string>,
    sliceStateSelector: (state: RootState) => PersonDataRemoteState<ItemType,FilterType>,
    queryParametersBuilder: QueryParameterBuilder<ItemType,FilterType>,
    filterComparer: FilterComparer<FilterType>
) => {
    const typedActions = actions as any as PersonDataReducers<ItemType,FilterType>;
    return {
        loadItems: loadPersonDataActionBuilder(
            args => `api/persons/${args!.personId}/${controllerName}`,
            queryParametersBuilder,
            filterComparer,
            () => resolveText("HealthRecordEntries_CouldNotLoad"),
            typedActions.setCurrentPersonId,
            typedActions.setIsLoading,
            typedActions.setItems,
            typedActions.addOrUpdateItems,
            typedActions.setHasMoreItems,
            typedActions.setLastUsedFilter,
            sliceStateSelector
        ),
        loadItem: loadItemActionBuilder(
            (args: LoadItemArgs) => `api/${controllerName}/${args.itemId}`,
            _ => ({}),
            () => resolveText("HealthRecordEntry_CouldNotLoad"),
            typedActions.setIsLoading,
            typedActions.addOrUpdateItem
        ),
        loadItemIfNotLoadedYet: loadItemIfNotYetLoadedActionBuilder(
            (args: LoadItemArgs) => `api/${controllerName}/${args.itemId}`,
            _ => ({}),
            () => resolveText("HealthRecordEntry_CouldNotLoad"),
            typedActions.setIsLoading,
            typedActions.addOrUpdateItem,
            sliceStateSelector
        ),
        addItem: postActionBuilder(
            () => `api/${controllerName}`, 
            () => resolveText("HealthRecordEntry_CouldNotStore"),
            typedActions.setIsSubmitting,
            (dispatch,response) => {
                dispatch(typedActions.addOrUpdateItem(response));
                // Reset health record entries slice filter to trigger load
                dispatch(healthRecordsSlice.actions.setLastUsedFilter(undefined));
            }
        ),
        deleteItem: deleteActionBuilder(
            (itemId: string) => `api/${controllerName}/${itemId}`,
            () => resolveText("HealthRecordEntry_SuccessfullyDeleted"),
            () => resolveText("HealthRecordEntry_CouldNotDelete"),
            (dispatch, args) => dispatch(typedActions.removeItem(args))
        )
    }
}
export const createHealthRecordEntryApiActions = <
    ItemType extends ViewModels.HealthRecordEntries.IHealthRecordEntryViewModelUnknownType,
    FilterType extends HealthRecordEventFilter
>(
    entryType: HealthRecordEntryType,
    controllerName: string,
    actions: CaseReducerActions<SliceCaseReducers<PersonDataRemoteState<ItemType,FilterType>>, string>,
    sliceStateSelector: (state: RootState) => PersonDataRemoteState<ItemType,FilterType>,
    queryParametersBuilder: QueryParameterBuilder<ItemType,FilterType>,
    filterComparer: FilterComparer<FilterType>
) => {
    const typedActions = actions as any as HealthRecordEntryReducers<ItemType,FilterType>;
    return {
        ...createPersonDataApiActions(
            controllerName, 
            actions, 
            sliceStateSelector, 
            queryParametersBuilder,
            filterComparer
        ),
        loadItems: loadPersonDataActionBuilder(
            args => `api/persons/${args!.personId}/${controllerName}`,
            queryParametersBuilder,
            filterComparer,
            () => resolveText("HealthRecordEntries_CouldNotLoad"),
            typedActions.setCurrentPersonId,
            typedActions.setIsLoading,
            typedActions.setItems,
            typedActions.addOrUpdateItems,
            typedActions.setHasMoreItems,
            typedActions.setLastUsedFilter,
            sliceStateSelector
        ),
        markItemAsSeen: postActionBuilder(
            (itemId: string) => `api/${controllerName}/${itemId}/seen`, 
            () => resolveText("HealthRecordEntry_CouldNotMarkAsSeen"),
            typedActions.setIsSubmitting,
            (dispatch,_,args) => dispatch(typedActions.markItemAsSeen(args))
        ),
        deleteItem: deleteActionBuilder(
            (itemId: string) => `api/${controllerName}/${itemId}`,
            () => resolveText("HealthRecordEntry_SuccessfullyDeleted"),
            () => resolveText("HealthRecordEntry_CouldNotDelete"),
            (dispatch, itemId) => {
                dispatch(typedActions.removeItem(itemId));
                dispatch(healthRecordsSlice.actions.removeItemOfType({
                    entryType: entryType,
                    entryId: itemId
                }));
            }
        )
    }
}