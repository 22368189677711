import { ClassificationType, DiagnosticTestScaleType } from "../../localComponents/types/enums";
import { Models } from "../../localComponents/types/models";
import { ViewModels } from "../../localComponents/types/viewModels";

export const LoincCodes = {
    
    // Observations
    Temperature: "8310-5",
    HeartRate: "8867-4",
    BloodPressureSystolic: "8480-6",
    BloodPressureDiastolic: "8462-4",
    OxygenSaturation: "20564-1",
    BodyHeight: "8302-2",
    BodyWeight: "29463-7",

    // TODO
    // Blood tests
    LeukocytesCount: "",
    ErythrocytesCount: "",
    Haematocrit: "",
    Haemoglobin: "",
    MeanCorpusculeVolume: "",
    MeanCorpusculeHaemoglobinConcentration: "",
    Creatinine: ""
};
export const UmlsCodes = {
    Occupation: "C0421456"
};
export const snomedCtViewModelToClassificationReference = (snomedCtConceptVM: ViewModels.SnomedCtConceptViewModel | undefined): Models.ClassificationReference | undefined => {
    if(!snomedCtConceptVM) {
        return undefined;
    }
    return {
        classification: ClassificationType.SnomedCt,
        conceptId: snomedCtConceptVM.id + '',
        displayName: snomedCtConceptVM.terms[0]
    };
}
export const icdCategoryToClassificationReference = (icdCategory: Models.Classifications.Icd.IcdCategory | undefined): Models.ClassificationReference | undefined => {
    if(!icdCategory) {
        return;
    }
    return {
        classification: ClassificationType.Icd11,
        conceptId: icdCategory.code,
        displayName: icdCategory.name
    };
}
export const loincConceptToClassificationReference = (loincConcept: Models.Classifications.LoincConcept | undefined): Models.ClassificationReference | undefined => {
    if(!loincConcept) {
        return undefined;
    }
    return {
        classification: ClassificationType.Loinc,
        conceptId: loincConcept.id,
        displayName: loincConcept.name
    };
}
export const orphaCodeToClassificationReference = (orphaCode: Models.Classifications.OrphaCode | undefined): Models.ClassificationReference | undefined => {
    if(!orphaCode) {
        return undefined;
    }
    return {
        classification: ClassificationType.OrphaCodes,
        conceptId: orphaCode.id + '',
        displayName: orphaCode.name
    };
}
export const toSnomedCtConceptViewModel = (classificationReference: Models.ClassificationReference | undefined): ViewModels.SnomedCtConceptViewModel | undefined => {
    if(!classificationReference) {
        return undefined;
    }
    if(classificationReference.classification !== ClassificationType.SnomedCt) {
        throw new Error(`Cannot convert classification reference to SNOMED-CT view model, because the classification is '${classificationReference.classification}'`);
    }
    return {
        id: Number(classificationReference.conceptId),
        terms: [ classificationReference.displayName ],
        textDefinitions: [],
        hasSubConcepts: false
    };
} 
export const toIcdCategory = (classificationReference: Models.ClassificationReference | undefined): Models.Classifications.Icd.IcdCategory | undefined => {
    if(!classificationReference) {
        return undefined;
    }
    if(classificationReference.classification !== ClassificationType.Icd11) {
        throw new Error(`Cannot convert classification reference to ICD category model, because the classification is '${classificationReference.classification}'`);
    }
    return {
        id: classificationReference.conceptId,
        code: classificationReference.conceptId,
        name: classificationReference.displayName,
        subEntries: [],
        translations: [],
        version: '11',
        affectedOrganSystems: []
    };
}
export const toLoincConcept = (classificationReference: Models.ClassificationReference | undefined): Models.Classifications.LoincConcept | undefined => {
    if(!classificationReference) {
        return undefined;
    }
    if(classificationReference.classification !== ClassificationType.Loinc) {
        throw new Error(`Cannot convert classification reference to LOINC model, because the classification is '${classificationReference.classification}'`);
    }
    return {
        id: classificationReference.conceptId,
        name: classificationReference.displayName,
        category: '',
        description: '',
        scaleType: DiagnosticTestScaleType.Undefined,
        translations: {}
    };
}
export const toOrphaCode = (classificationReference: Models.ClassificationReference | undefined): Models.Classifications.OrphaCode | undefined => {
    if(!classificationReference) {
        return undefined;
    }
    if(classificationReference.classification !== ClassificationType.OrphaCodes) {
        throw new Error(`Cannot convert classification reference to OrphaCode, because the classification is '${classificationReference.classification}'`);
    }
    return {
        id: Number(classificationReference.conceptId),
        name: classificationReference.displayName,
        expertLink: `http://www.orpha.net/consor/cgi-bin/OC_Exp.php?lng=en&Expert=${classificationReference.conceptId}`,
        translations: {}
    };
}
export const getLoincCode = (classificationReference: Models.ClassificationReference) => {
    if(classificationReference.classification !== ClassificationType.Loinc) {
        throw new Error("Expected LOINC classification");
    }
    return classificationReference.conceptId;
}
export const getSnomedCtConceptId = (classificationReference: Models.ClassificationReference) => {
    if(classificationReference.classification !== ClassificationType.SnomedCt) {
        throw new Error("Expected SNOMED-CT classification");
    }
    return classificationReference.conceptId;
}
export const getIcd11Code = (classificationReference: Models.ClassificationReference) => {
    if(classificationReference.classification !== ClassificationType.Icd11) {
        throw new Error("Expected ICD-11 classification");
    }
    return classificationReference.conceptId;
}
export const getOrphaCodeId = (classificationReference: Models.ClassificationReference) => {
    if(classificationReference.classification !== ClassificationType.OrphaCodes) {
        throw new Error("Expected OrphaCode");
    }
    return classificationReference.conceptId;
}
export const areClassificationReferencesEqual = (c1: Models.ClassificationReference, c2: Models.ClassificationReference) => {
    return c1.classification === c2.classification && c1.conceptId === c2.conceptId;
}
export const serializeClassificationReference = (classificationReference: Models.ClassificationReference) => {
    return `${classificationReference.classification}|${classificationReference.conceptId}`;
}