import { Nav, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import ToolsDropdownMenu from './ToolsDropdownMenu';
import { AboutMenu } from './AboutMenu';
import { ShehrdUrls } from '../../navigation/Urls';
import { SharedUrls } from '../../../sharedHealthComponents/navigation/Urls';

interface NoUserMenuProps {
    isLoggingIn: boolean;
    onLogOut: () => void;
}

export const NoUserMenu = (props: NoUserMenuProps) => {

    const navigate = useNavigate();
    
    return (
    <>
        <Nav>
            <Nav.Link onClick={() => navigate("/")}>{resolveText("Home")}</Nav.Link>
            <Nav.Link onClick={() => navigate(ShehrdUrls.EPIDEMIOLOGY_DASHBOARD)}>{resolveText("Epidemiology")}</Nav.Link>
            <Nav.Link onClick={() => navigate(ShehrdUrls.PRODUCTS)}>{resolveText("Products")}</Nav.Link>
            <ToolsDropdownMenu />
            <AboutMenu />
        </Nav>
        <Nav className='ms-auto me-3'>
            <Nav.Link onClick={() => navigate(ShehrdUrls.REGISTER)}>{resolveText("Register")}</Nav.Link>
            {props.isLoggingIn
            ? <Button
                variant="danger"
                onClick={props.onLogOut}
            >
                {resolveText('LogOut')}
            </Button>
            : <Button 
                onClick={() => navigate(SharedUrls.LOGIN)}
            >
                {resolveText("Login")}
            </Button>}
        </Nav>
    </>
    );

}