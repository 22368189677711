import { NavDropdown } from "react-bootstrap";
import { resolveText } from "../../../sharedCommonComponents/helpers/Globalizer";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from "../../contexts/UserContext";
import { ToolsUrls } from "../../navigation/Urls";

interface ToolsDropdownMenuProps {}

export const ToolsDropdownMenu = (props: ToolsDropdownMenuProps) => {

    const user = useContext(UserContext);
    const navigate = useNavigate();

    return (
        <NavDropdown title={resolveText("Tools")}>
            <NavDropdown.Item onClick={() => navigate(ToolsUrls.CPR)}>{resolveText("CPR")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate(ToolsUrls.NEW_CHAT)}>{resolveText("TranslatedChat")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate(ToolsUrls.CHECKLISTS)}>{resolveText("Checklists")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate(user? ToolsUrls.ISITBROKEN_CASES : ToolsUrls.ISITBROKEN)}>{resolveText("IsItBroken")}</NavDropdown.Item>
        </NavDropdown>
    );

}
export default ToolsDropdownMenu;