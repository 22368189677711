import { createVerb, DataTypeNames, editVerb, UrlIdPlaceholders as SharedUrlIdPlaceholders } from "../../sharedHealthComponents/navigation/Urls"
import { ToolsUrls as SharedToolsUrls } from "../../sharedHealthComponents/navigation/Urls"

export const UrlIdPlaceholders = {
    ...SharedUrlIdPlaceholders,
    ACCOUNTTYPE: ':accountType',
    EMERGENCYTOKEN: ':emergencyToken',
    UPLOADCODE: ':uploadCode',
    FORMID: ':formId',
    SHOPORDER: ':orderId',
    STUDY: ':studyId',
    STUDYENROLLMENT: ':enrollmentId',
    GIT_BRANCHNAME: ':branchName',
    SHAREDACCESS: ':accessId',
    ACCESSINVITE: ':accessInviteId',
};
export const ShehrdUrls = {
    LOGIN_REDIRECT: "/login/redirect",
    REGISTER: "/register",
    REGISTER_ACCOUNTTYPE: `/register/${UrlIdPlaceholders.ACCOUNTTYPE}`,
    VERIFY_EMAIL: "/verify-email",
    RESET_PASSWORD: "/reset-password",
    ACCOUNT_SELECTION: "/accountselection",
    ACCOUNT_NOTACTIVATED: "/error/notactivated",
    REQUEST_RESET_PASSWORD: "/request-password-reset",
    VERIFY_CONTACT_EMAIL: "/verify-contact-email",
    CONNECT_CLOUD_STORAGE: "/storage/cloud",
    DROPBOX_AUTHORIZATION: "/storage/dropbox/connected",
    ACCOUNTS: '/accounts',
    REVIEW_ACCOUNT: `/account/${UrlIdPlaceholders.ID}`,

    EPIDEMIOLOGY_DASHBOARD: "/epidemiology/dashboard",
    EMERGENCYTOKEN_ACCESS: `/emergency/${UrlIdPlaceholders.EMERGENCYTOKEN}`,
    ANONYMOUS_UPLOAD: `/upload/${UrlIdPlaceholders.UPLOADCODE}`,
    CREATE_UPLOADCODE: `/${createVerb}/uploadcode`,
    ANONYMOUS_FORM: `/form/${UrlIdPlaceholders.FORMID}`,
    ANONYMOUS_FORMS: '/anonymousforms',
    CREATE_ANONYMOUS_FORM: `/${createVerb}/anonymousform`,
    EDIT_ANONYMOUS_FORM: `/${editVerb}/anonymousform/${UrlIdPlaceholders.ID}`,
    ANSWER_ANONYMOUS_FORM: `/anonymousforms/${UrlIdPlaceholders.FORMID}/answer`,

    STUDIES: '/studies',
    CREATE_STUDY: `/${createVerb}/study`,
    EDIT_STUDY: `/${editVerb}/study/${UrlIdPlaceholders.ID}`,
    OPEN_STUDY: `/study/${UrlIdPlaceholders.ID}`,
    STUDY_OFFER_PARTICIPATION: `/study/${UrlIdPlaceholders.STUDY}/offerparticipation`,
    STUDY_ENROLLMENT_REVIEW: `/study/${UrlIdPlaceholders.STUDY}/enrollment/${UrlIdPlaceholders.STUDYENROLLMENT}/review`,

    PUBLICDATAAPPROVAL: '/publicdataapproval',
    PUBLICHEALTHAGENCIES: 'publichealthagencies',
    PUBLICHEALTHAGENCY_SETTINGS: "/agency/settings",

    INSTITUTION_MANAGE: `/institution/${UrlIdPlaceholders.INSTITUTION}/manage`,
    INSTITUTION_SETTINGS: `/institution/${UrlIdPlaceholders.INSTITUTION}/settings`,

    PATIENTS: '/patients',
    PRACTITIONERS: `/${DataTypeNames.PRACTITIONERS}`,
    CREATE_PRACTITIONER: `/${createVerb}/${DataTypeNames.PRACTITIONER}`,
    EDIT_PRACTITIONER: `/${editVerb}/${DataTypeNames.PRACTITIONER}/${UrlIdPlaceholders.ID}`,

    SHAREDACCESSES: '/sharedaccess',
    GIVE_ACCESS: `/giveaccess/healthprofessional`,
    OPEN_ACCESSINVITE: `/giveaccess/healthprofessional/${UrlIdPlaceholders.ACCESSINVITE}`,
    ACCESS_HANDSHAKE: `/accessinvites/${UrlIdPlaceholders.ACCESSINVITE}`,
    CREATE_EMERGENCY: `/${createVerb}/emergency`,
    SHOW_EMERGENCYTOKEN: `/show/emergencytoken/${UrlIdPlaceholders.SHAREDACCESS}`,
    REQUEST_EMERGENCY_ACCESS: `/emergency/${UrlIdPlaceholders.EMERGENCYTOKEN}`,

    GIT_BRANCH_COMPARE: `/git/branch/${UrlIdPlaceholders.GIT_BRANCHNAME}`,
    ACCESSLOG: '/accesslog',
    MY_REPRESENTATIONS: '/representations',

    FAQ: "/faq",
    ABOUT_EHDS: "/about/ehds",
    TERMS_OF_SERVICE: "/terms-of-service",
    CONTACT: "/contact",
    PRODUCTS: "/products",
    PRODUCTS_SHEHRD: "/product/shehrd",
    PRODUCTS_LOCALSHEHRD: "/product/local-shehrd",
};
export const ShopUrls = {
    CHECKOUT: '/shop/checkout',
    EMERGENCY_CARD: '/shop/emergency-card',
    OPEN_ORDER: `/shop/order/${UrlIdPlaceholders.SHOPORDER}`,
    ORDERS: '/shop/orders',
    VOUCHERS: '/shop/vouchers',
    CREATE_VOUCHER: '/shop/create/voucher',
    EDIT_VOUCHER: `/shop/edit/voucher/${UrlIdPlaceholders.ID}`,
    ITEMS: '/shop/items',
    OPEN_ITEM: `/shop/items/${UrlIdPlaceholders.ID}`,
    CREATE_ITEM: '/shop/create/item',
    EDIT_ITEM: `/shop/edit/item/${UrlIdPlaceholders.ID}`,
    SHIPPINGMETHODS: '/shop/shippingmethods',
    CREATE_SHIPPINGMETHOD: '/shop/create/shippingmethod',
    EDIT_SHIPPINGMETHOD: `/shop/edit/shippingmethod/${UrlIdPlaceholders.ID}`,
    EMERGENCYCARD_QR_GENERATOR: '/emergencycard-qr-generator'
};
export const ToolsUrls = {
    ...SharedToolsUrls,
    CPR: '/tools/cpr',
    CHECKLISTS: '/tools/checklists',

    ISITBROKEN: '/isitbroken',
    ISITBROKEN_CONFIGURATION: '/isitbroken/configuration',
    ISITBROKEN_CASES: '/isitbroken/cases',
    ISITBROKEN_ACTIVATE_CASE: `/isitbroken/cases/${UrlIdPlaceholders.ID}/activate`,
    ISITBROKEN_ANSWER: `/isitbroken/cases/${UrlIdPlaceholders.ID}/answer`,
    ISITBROKEN_GENERATE: '/isitbroken/generate'
};