import { useContext } from 'react';
import { Nav, NavDropdown, NavItem, NavLink } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import UserContext from '../../contexts/UserContext';
import { getPerson } from '../../helpers/UserHelpers';
import { ShehrdUrls, ToolsUrls } from '../../navigation/Urls';
import { buildHealthRecordUrl, SharedUrls } from '../../../sharedHealthComponents/navigation/Urls';

interface SharerMenuProps {}

export const SharerMenu = (props: SharerMenuProps) => {

    const user = useContext(UserContext)!;
    const personId = getPerson(user)!.id;
    const navigate = useNavigate();
    return (
        <Nav>
            <NavItem>
                <NavLink onClick={() => navigate(buildHealthRecordUrl(personId))}>{resolveText("Menu_MyData")}</NavLink>
            </NavItem>
            <NavDropdown title={resolveText("Menu_HealthServices")}>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.APPOINTMENTS)}>{resolveText("Menu_Appointments")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.CREATE_APPOINTMENT)}>{resolveText("Menu_NewAppointment")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.ENCOUNTERS)}>{resolveText("Menu_EncounterHistory")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.INSTITUTIONS)}>{resolveText("Menu_FindInstitution")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShehrdUrls.PRACTITIONERS)}>{resolveText("Menu_FindPractitioner")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShehrdUrls.PUBLICHEALTHAGENCIES)}>{resolveText("Menu_MyPublicHealthAgencies")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ToolsUrls.NEW_CHAT)}>{resolveText("TranslatedChat")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={resolveText("Menu_Permissions")}>
                <NavDropdown.Item onClick={() => navigate(ShehrdUrls.SHAREDACCESSES)}>{resolveText("Menu_SharedAccess")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShehrdUrls.MY_REPRESENTATIONS)}>{resolveText("Menu_MyRepresentations")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShehrdUrls.ACCESSLOG)}>{resolveText("Menu_AccessLog")}</NavDropdown.Item>
            </NavDropdown>
            <NavItem>
                <NavLink onClick={() => navigate(ShehrdUrls.STUDIES)}>{resolveText("Menu_Studies")}</NavLink>
            </NavItem>
            <NavDropdown title={resolveText("Menu_Config")}>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.LABTEST_PACKAGES)}>{resolveText("DiagnosticTestsPackages")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.QUESTIONNAIRES)}>{resolveText("Questionnaires")}</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );

}