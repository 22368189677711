import { lazy } from 'react';
import { Models } from '../types/models';
import { ViewModels } from '../types/viewModels';
import { RouteDefinition } from '../../sharedCommonComponents/types/frontendTypes';
import { ChecklistRoutes } from '../../checklist/navigation/ChecklistRoutes';
import ToolsRoutes from './ToolsRoutes';
import { SharedUrls } from '../../sharedHealthComponents/navigation/Urls';
import { ShehrdUrls } from './Urls';

const AnonymousFormAnswerPage = lazy(() => import('../pages/AnonymousForms/AnonymousFormAnswerPage'));
const AnonymousUploadPage = lazy(() => import('../pages/Sharer/AnonymousUploadPage'));
const AccountSelectionPage = lazy(() => import('../pages/UserManagement/AccountSelectionPage'));
const LoginRedirectPage = lazy(() => import('../pages/UserManagement/LoginRedirectPage'));
const TermsAndConditionsPage = lazy(() => import('../pages/Public/TermsAndConditionsPage'));
const EpidemiologyDashboardPage = lazy(() => import('../pages/Epidemiology/EpidemiologyDashboardPage'));
const RequestPasswordResetPage = lazy(() => import('../pages/UserManagement/RequestPasswordResetPage'));
const ResetPasswordPage = lazy(() => import('../pages/UserManagement/ResetPasswordPage'));
const VerifyEmailPage = lazy(() => import('../pages/UserManagement/VerifyEmailPage'));
const AccountContactEmailVerificationPage = lazy(() => import('../pages/UserManagement/AccountContactEmailVerificationPage'));
const EmergencyPage = lazy(() => import('../pages/EmergencyPage'));
const FrequentlyAskedQuestionsPage = lazy(() => import('../pages/Public/FrequentlyAskedQuestionsPage'));
const NotActivatedAccountPage = lazy(() => import('../pages/UserManagement/NotActivatedAccountPage'));
const ProductsPage = lazy(() => import('../pages/Public/ProductsPage'));
const ProductShehrdPage = lazy(() => import('../pages/Public/ProductShehrdPage'));
const ProductLocalShehrdPage = lazy(() => import('../pages/Public/ProductLocalShehrdPage'));
const EuropeanHealthDataSpacePage = lazy(() => import('../pages/Public/EuropeanHealthDataSpacePage'));
const LoginPage = lazy(() => import('../pages/UserManagement/LoginPage'));
const RegisterAccountPage = lazy(() => import('../pages/UserManagement/RegisterAccountPage'));
const HomePage = lazy(() => import('../pages/Public/HomePage'));
const ContactPage = lazy(() => import('../pages/Public/ContactPage'));
const PrivacyPage = lazy(() => import('../pages/Public/PrivacyPage'));
const RedirectToLoginPage = lazy(() => import('../pages/RedirectToLoginPage'));

interface NoUserRoutesProps {
    onNewAuthenticationResult: (authenticationResult: Models.AccessControl.AuthenticationResult) => void;
    onLoggedIn: (userViewModel: ViewModels.IUserViewModel | null, redirectUrl?: string) => void;
    onLogOut: () => Promise<void>;
    features: Models.Configuration.FeatureSettings;
}
export const NoUserRoutes = (props: NoUserRoutesProps) => {

    const { onNewAuthenticationResult, onLoggedIn, onLogOut, features } = props;
    const routes: RouteDefinition[] = [
        // Anonymous forms
        { path: ShehrdUrls.ANONYMOUS_FORM, element: <AnonymousFormAnswerPage /> },

        // Health record access
        { path: ShehrdUrls.EMERGENCYTOKEN_ACCESS, element: <EmergencyPage onNewAccessToken={onNewAuthenticationResult} onGuestLogin={onLoggedIn} /> },
        { path: ShehrdUrls.ANONYMOUS_UPLOAD, element: <AnonymousUploadPage /> },

        // Account
        { path: SharedUrls.LOGIN, element: <LoginPage onNewAccessToken={onNewAuthenticationResult} /> },
        { path: ShehrdUrls.LOGIN_REDIRECT, element: <LoginRedirectPage /> },
        { path: ShehrdUrls.ACCOUNT_SELECTION, element: <AccountSelectionPage onNewAccessTokenReceived={onNewAuthenticationResult} onLoggedIn={onLoggedIn} /> },
        { path: ShehrdUrls.ACCOUNT_NOTACTIVATED, element: <NotActivatedAccountPage onLogOut={onLogOut} /> },
        { path: ShehrdUrls.REGISTER, element: <RegisterAccountPage onNewAccessToken={onNewAuthenticationResult} onLoggedIn={onLoggedIn} /> },
        { path: ShehrdUrls.REGISTER_ACCOUNTTYPE, element: <RegisterAccountPage onNewAccessToken={onNewAuthenticationResult} onLoggedIn={onLoggedIn} /> },
        { path: ShehrdUrls.VERIFY_EMAIL, element: <VerifyEmailPage /> },
        { path: ShehrdUrls.RESET_PASSWORD, element: <ResetPasswordPage onNewAccessToken={onNewAuthenticationResult} /> },
        { path: ShehrdUrls.REQUEST_RESET_PASSWORD, element: <RequestPasswordResetPage /> },
        { path: ShehrdUrls.VERIFY_CONTACT_EMAIL, element: <AccountContactEmailVerificationPage /> },

        // About shEHRd
        { path: ShehrdUrls.FAQ, element: <FrequentlyAskedQuestionsPage /> },
        { path: ShehrdUrls.ABOUT_EHDS, element: <EuropeanHealthDataSpacePage /> },
        { path: SharedUrls.PRIVACY_STATEMENT, element: <PrivacyPage /> },
        { path: ShehrdUrls.TERMS_OF_SERVICE, element: <TermsAndConditionsPage /> },
        { path: ShehrdUrls.CONTACT, element: <ContactPage /> },
        { path: ShehrdUrls.EPIDEMIOLOGY_DASHBOARD, element: <EpidemiologyDashboardPage /> },
        { path: "/", element: <HomePage /> },

        // Business
        { path: ShehrdUrls.PRODUCTS, element: <ProductsPage /> },
        { path: ShehrdUrls.PRODUCTS_SHEHRD, element: <ProductShehrdPage /> },
        { path: ShehrdUrls.PRODUCTS_LOCALSHEHRD, element: <ProductLocalShehrdPage /> },

        // Tools
        ...ToolsRoutes(features, onNewAuthenticationResult, onLogOut),
        ...ChecklistRoutes(),

        { path: '*', element: <RedirectToLoginPage /> },
    ];

    return routes;

}
export default NoUserRoutes;