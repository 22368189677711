import { lazy } from 'react';
import { AccountType } from '../types/enums';
import { Models } from '../types/models';
import { ViewModels } from '../types/viewModels';
import ShehrdHealthRecordRoutes from './ShehrdHealthRecordRoutes';
import { ShehrdRouteDefinition } from '../types/frontendTypes';
import ChecklistRoutes from '../../checklist/navigation/ChecklistRoutes';
import { ToolsRoutes } from './ToolsRoutes';
import { HealthRecordUrls, SharedUrls } from '../../sharedHealthComponents/navigation/Urls';
import { ShehrdUrls, ShopUrls, ToolsUrls } from './Urls';


const HealthRecordEnvironment = lazy(() => import('../../sharedHealthComponents/pages/Patients/HealthRecordEnvironment'));
const EditPersonPage = lazy(() => import('../../sharedHealthComponents/pages/Patients/EditPersonPage'));
const ConnectCloudStoragePage = lazy(() => import('../pages/Sharer/CloudStorage/ConnectCloudStoragePage'));
const DropboxAuthorizedPage = lazy(() => import('../pages/Sharer/CloudStorage/DropboxAuthorizedPage'));
const PublicHealthAgencyAssociationPage = lazy(() => import('../pages/Sharer/PublicHealthAgencyAssociationPage'));
const PublicHealthAgencySettingsPage = lazy(() => import('../pages/PublicHealthAgency/PublicHealthAgencySettingsPage'));
const EmergencyCardQrCodeGeneratorPage = lazy(() => import('../pages/Admin/EmergencyCardQrCodeGeneratorPage'));
const TermsAndConditionsPage = lazy(() => import('../pages/Public/TermsAndConditionsPage'));
const EuropeanHealthDataSpacePage = lazy(() => import('../pages/Public/EuropeanHealthDataSpacePage'));
const FrequentlyAskedQuestionsPage = lazy(() => import('../pages/Public/FrequentlyAskedQuestionsPage'));
const FeedbackListPage = lazy(() => import('../pages/Admin/FeedbackListPage'));
const AnonymousFormAnswerPage = lazy(() => import('../pages/AnonymousForms/AnonymousFormAnswerPage'));
const AnonymousFormListPage = lazy(() => import('../pages/AnonymousForms/AnonymousFormListPage'));
const CreateEditAnonymousFormPage = lazy(() => import('../pages/AnonymousForms/CreateEditAnonymousFormPage'));
const EpidemiologyDashboardPage = lazy(() => import('../pages/Epidemiology/EpidemiologyDashboardPage'));
const PublicDataApprovalPage = lazy(() => import('../pages/Admin/PublicDataApprovalPage'));
const PublicHealthAgencyHomePage = lazy(() => import('../pages/PublicHealthAgency/PublicHealthAgencyHomePage'));
const PrintTemplateListPage = lazy(() => import('../../sharedHealthComponents/pages/PrintTemplateListPage'));
const PrintTemplateEditorPage = lazy(() => import('../../sharedHealthComponents/pages/PrintTemplateEditorPage'));
const AccessLogPage = lazy(() => import('../pages/Sharer/AccessLogPage'));
const MyRepresentationsPage = lazy(() => import('../pages/Sharer/MyRepresentationsPage'));
const PractitionersListPage = lazy(() => import('../../sharedHealthComponents/pages/Organization/PractitionersListPage'));
const MyEncoutnersPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/MyEncoutnersPage'));
const AppointmentsListPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/AppointmentsListPage'));
const PrintAppointmentPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/PrintAppointmentPage'));
const AccountsPage = lazy(() => import('../pages/UserManagement/AccountsPage'));
const AccountReviewPage = lazy(() => import('../pages/UserManagement/AccountReviewPage'));
const AccountContactEmailVerificationPage = lazy(() => import('../pages/UserManagement/AccountContactEmailVerificationPage'));
const PatientsListPage = lazy(() => import('../pages/HealthProfessional/PatientsListPage'));
const StudiesPage = lazy(() => import('../pages/Researcher/StudiesPage'));
const StudyPage = lazy(() => import('../pages/Researcher/StudyPage'));
const GiveHealthProfesionalAccessPage = lazy(() => import('../pages/Sharer/GiveHealthProfesionalAccessPage'));
const ReceiveHealthProfessionalAccessPage = lazy(() => import('../pages/HealthProfessional/ReceiveHealthProfessionalAccessPage'));
const SharedAccessListPage = lazy(() => import('../pages/Sharer/SharedAccessListPage'));
const OfferStudyParticipationPage = lazy(() => import('../pages/Sharer/OfferStudyParticipationPage'));
const CreateEditStudyPage = lazy(() => import('../pages/Researcher/CreateEditStudyPage'));
const StudyEnrollmentReviewPage = lazy(() => import('../pages/Researcher/StudyEnrollmentReviewPage'));
const RequestEmergencyAccessPage = lazy(() => import('../pages/HealthProfessional/RequestEmergencyAccessPage'));
const CreateEmergencyAccessTokenPage = lazy(() => import('../pages/Sharer/CreateEmergencyAccessTokenPage'));
const AccountPage = lazy(() => import('../pages/UserManagement/AccountPage'));
const CreateEditAppointmentPage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/CreateEditAppointmentPage'));
const CreateEditInstitutionPage = lazy(() => import('../pages/Organizations/CreateEditInstitutionPage'));
const EditInstitutionSchedulePage = lazy(() => import('../../sharedHealthComponents/pages/Scheduling/EditInstitutionSchedulePage'));
const ManageInstitutionPage = lazy(() => import('../pages/Organizations/ManageInstitutionPage'));
const CreateEditDepartmentPage = lazy(() => import('../pages/Organizations/CreateEditDepartmentPage'));
const CreateEditPractitionerPage = lazy(() => import('../../sharedHealthComponents/pages/Organization/CreateEditPractitionerPage'));
const MyAppointmentsPage = lazy(() => import('../pages/Sharer/MyAppointmentsPage'));
const DiagnosticTestsPackagesExplorerPage = lazy(() => import('../../sharedHealthComponents/pages/TestResults/DiagnosticTestsPackagesExplorerPage'));
const CreateUploadCodePage = lazy(() => import('../pages/Sharer/CreateUploadCodePage'));
const InstitutionsListPage = lazy(() => import('../pages/Organizations/InstitutionsListPage'));
const EditInstitutionSettingsPage = lazy(() => import('../pages/Organizations/EditInstitutionSettingsPage'));
const GitBranchComparisonPage = lazy(() => import('../pages/Sharer/GitBranchComparisonPage'));
const ExportPatientDataPage = lazy(() => import('../../sharedHealthComponents/pages/DataImportExport/ExportPatientDataPage'));
const CheckoutPage = lazy(() => import('../pages/Billing/CheckoutPage'));
const ShippingMethodsPage = lazy(() => import('../pages/Billing/ShippingMethodsPage'));
const CreateEditShippingMethodPage = lazy(() => import('../pages/Billing/CreateEditShippingMethodPage'));
const EmergencyCardOrderPage = lazy(() => import('../pages/Billing/EmergencyCardOrderPage'));
const OrderProgressPage = lazy(() => import('../pages/Billing/OrderProgressPage'));
const MyOrdersPage = lazy(() => import('../pages/Billing/MyOrdersPage'));
const ShopItemsAdminPage = lazy(() => import('../pages/Billing/ShopItemsAdminPage'));
const VouchersListPage = lazy(() => import('../pages/Billing/VouchersListPage'));
const CreateEditVoucherPage = lazy(() => import('../pages/Billing/CreateEditVoucherPage'));
const CreateEditShopItemPage = lazy(() => import('../pages/Billing/CreateEditShopItemPage'));
const EquipmentMeasurementsListPage = lazy(() => import('../../sharedHealthComponents/pages/Equipments/EquipmentMeasurementsListPage'));
const CreateEditEquipmentMeasurement = lazy(() => import('../../sharedHealthComponents/pages/Equipments/CreateEditEquipmentMeasurement'));
const DrugsListPage = lazy(() => import('../../sharedHealthComponents/pages/Medication/DrugsListPage'));
const CreateEditDrugPage = lazy(() => import('../../sharedHealthComponents/pages/Medication/CreateEditDrugPage'));
const PharmacyOrdersListPage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/PharmacyOrdersListPage'));
const CreateEditPharmacyOrder = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/CreateEditPharmacyOrderPage'));
const PharmacyOrderPage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/PharmacyOrderPage'));
const QuestionnairesListPage = lazy(() => import('../../sharedHealthComponents/pages/Questionnaires/QuestionnairesListPage'));
const CreateEditQuestionnairePage = lazy(() => import('../../sharedHealthComponents/pages/Questionnaires/CreateEditQuestionnairePage'));
const CreateEditTasksPackagePage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/CreateEditTasksPackagePage'));
const CreateEditTodoListItemPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/CreateEditTodoListItemPage'));
const DeployTasksPackagePage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/DeployTasksPackagePage'));
const PrintTaskListPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/PrintTaskListPage'));
const TaskPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/TaskPage'));
const TasksPackagesPage = lazy(() => import('../../sharedHealthComponents/pages/Collaboration/TasksPackagesPage'));
const IsItBrokenConfigurationPage = lazy(() => import('../pages/IsItBroken/IsItBrokenConfigurationPage'));
const AdminHomePage = lazy(() => import('../pages/Admin/AdminHomePage'));
const ResearcherHomePage = lazy(() => import('../pages/Researcher/ResearcherHomePage'));
const PharmacyHomePage = lazy(() => import('../../sharedHealthComponents/pages/Pharmacy/PharmacyHomePage'));
const SharerHomePage = lazy(() => import('../pages/Sharer/SharerHomePage'));
const HealthProfessionalHomePage = lazy(() => import('../pages/HealthProfessional/HealthProfessionalHomePage'));
const HomePage = lazy(() => import('../pages/Public/HomePage'));
const ContactPage = lazy(() => import('../pages/Public/ContactPage'));
const PrivacyPage = lazy(() => import('../pages/Public/PrivacyPage'));
const NotFoundPage = lazy(() => import('../pages/NotFoundPage'));

interface RegisteredUserRoutesProps {
    user: ViewModels.IUserViewModel;
    features: Models.Configuration.FeatureSettings;
    onNewAuthenticationResult: (authenticationResult: Models.AccessControl.AuthenticationResult) => void;
    onLogOut: () => Promise<void>;
}
export const RegisteredUserRoutes = (props: RegisteredUserRoutesProps) => {

    const { user, features, onNewAuthenticationResult, onLogOut } = props;

    let userTypeHomePage = (<HomePage />);
    switch(user.accountType) {
        case AccountType.Admin:
            userTypeHomePage = (<AdminHomePage />);
            break;
        case AccountType.HealthProfessional:
            userTypeHomePage = (<HealthProfessionalHomePage />);
            break;
        case AccountType.Researcher:
            userTypeHomePage = (<ResearcherHomePage />);
            break;
        case AccountType.Pharmacy:
            userTypeHomePage = (<PharmacyHomePage />);
            break;
        case AccountType.Sharer:
            userTypeHomePage = (<SharerHomePage />);
            break;
        case AccountType.PublicHealthAgency:
            userTypeHomePage = (<PublicHealthAgencyHomePage />);
            break;
    }

    const routes: ShehrdRouteDefinition[] = [
        { path: `${HealthRecordUrls.HEALTHRECORD_BASEURL}/*`, element: <HealthRecordEnvironment routes={ShehrdHealthRecordRoutes(features)} />, usesCustomLayout: true },
        
        { path: ShehrdUrls.FAQ, element: <FrequentlyAskedQuestionsPage />, audience: [] },
        { path: ShehrdUrls.ABOUT_EHDS, element: <EuropeanHealthDataSpacePage />, audience: [] },
        { path: SharedUrls.PRIVACY_STATEMENT, element: <PrivacyPage />, audience: [] },
        { path: ShehrdUrls.TERMS_OF_SERVICE, element: <TermsAndConditionsPage />, audience: [] },
        { path: ShehrdUrls.CONTACT, element: <ContactPage />, audience: [] },
        { path: '/', element: userTypeHomePage, audience: [] },
        { path: '*', element: <NotFoundPage />, audience: [] },

        { path: ShehrdUrls.CREATE_STUDY, element: <CreateEditStudyPage />, audience: [ AccountType.Researcher ]},
        { path: ShehrdUrls.EDIT_STUDY, element: <CreateEditStudyPage />, audience: [ AccountType.Researcher ]},
        { path: ShehrdUrls.OPEN_STUDY, element: <StudyPage />, audience: [ AccountType.Researcher, AccountType.Sharer, AccountType.Admin ]},
        { path: ShehrdUrls.STUDY_OFFER_PARTICIPATION, element: <OfferStudyParticipationPage />, audience: [ AccountType.Sharer ]},
        { path: ShehrdUrls.STUDY_ENROLLMENT_REVIEW, element: <StudyEnrollmentReviewPage />, audience: [ AccountType.Researcher ]},
        { path: ShehrdUrls.STUDIES, element: <StudiesPage />, audience: [ AccountType.Researcher, AccountType.Sharer, AccountType.Admin ]},
        { path: ShehrdUrls.OPEN_ACCESSINVITE, element: <GiveHealthProfesionalAccessPage />, audience: [ AccountType.Sharer ]},
        { path: ShehrdUrls.GIVE_ACCESS, element: <GiveHealthProfesionalAccessPage />, audience: [ AccountType.Sharer ]},
        { 
            path: ShehrdUrls.ACCESS_HANDSHAKE, 
            element: user.accountType === AccountType.Sharer ? <GiveHealthProfesionalAccessPage /> : <ReceiveHealthProfessionalAccessPage />, 
            audience: [ AccountType.Sharer, AccountType.HealthProfessional ]
        },
        { path: ShehrdUrls.SHAREDACCESSES, element: <SharedAccessListPage />, audience: [ AccountType.Sharer ]},
        { path: ShehrdUrls.CREATE_EMERGENCY, element: <CreateEmergencyAccessTokenPage />, audience: [ AccountType.Sharer ]},
        { path: ShehrdUrls.CREATE_EMERGENCY, element: <RequestEmergencyAccessPage />, audience: [ AccountType.HealthProfessional ]},
        { path: ShehrdUrls.REQUEST_EMERGENCY_ACCESS, element: <RequestEmergencyAccessPage />, audience: [ AccountType.HealthProfessional ]},
        { path: ShehrdUrls.SHOW_EMERGENCYTOKEN, element: <CreateEmergencyAccessTokenPage />, audience: [ AccountType.Sharer ]},
        { path: ShehrdUrls.MY_REPRESENTATIONS, element: <MyRepresentationsPage />, audience: [ AccountType.Sharer ]},
        { path: ShehrdUrls.ACCESSLOG, element: <AccessLogPage />, audience: [ AccountType.Sharer ]},

        { path: SharedUrls.MY_ACCOUNT, element: <AccountPage onAccountDeleted={onLogOut} />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.PublicHealthAgency, AccountType.Admin ]},
        { path: HealthRecordUrls.EDIT_PERSON, element: <EditPersonPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ] },
        { path: ShehrdUrls.GIT_BRANCH_COMPARE, element: <GitBranchComparisonPage />, audience: [ AccountType.Sharer ]},
        { path: ShehrdUrls.ACCOUNTS, element: <AccountsPage />, audience: [ AccountType.Admin ]},
        { path: ShehrdUrls.REVIEW_ACCOUNT, element: <AccountReviewPage />, audience: [ AccountType.Admin ]},
        { path: ShehrdUrls.VERIFY_CONTACT_EMAIL, element: <AccountContactEmailVerificationPage />, audience: [] },
        { path: SharedUrls.INSTITUTIONS, element: <InstitutionsListPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy, AccountType.Admin ]},
        { path: SharedUrls.CREATE_INSTITUTION, element: <CreateEditInstitutionPage />, audience: [ AccountType.Admin ]},
        { path: SharedUrls.EDIT_INSTITUTION, element: <CreateEditInstitutionPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: SharedUrls.INSTITUTION_EDIT_SCHEDULE, element: <EditInstitutionSchedulePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: SharedUrls.INSTITUTION_DEPARTMENT_EDIT_SCHEDULE, element: <EditInstitutionSchedulePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: ShehrdUrls.INSTITUTION_MANAGE, element: <ManageInstitutionPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: ShehrdUrls.INSTITUTION_SETTINGS, element: <EditInstitutionSettingsPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: SharedUrls.INSTITUTION_CREATE_DEPARTMENT, element: <CreateEditDepartmentPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: SharedUrls.INSTITUTION_EDIT_DEPARTMENT, element: <CreateEditDepartmentPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: ShehrdUrls.PRACTITIONERS, element: <PractitionersListPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy, AccountType.Admin ]},
        { path: ShehrdUrls.CREATE_PRACTITIONER, element: <CreateEditPractitionerPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: ShehrdUrls.EDIT_PRACTITIONER, element: <CreateEditPractitionerPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},

        { path: ShehrdUrls.ANSWER_ANONYMOUS_FORM, element: <AnonymousFormAnswerPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: ShehrdUrls.ANONYMOUS_FORMS, element: <AnonymousFormListPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: ShehrdUrls.CREATE_ANONYMOUS_FORM, element: <CreateEditAnonymousFormPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: ShehrdUrls.EDIT_ANONYMOUS_FORM, element: <CreateEditAnonymousFormPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},

        { path: ShehrdUrls.PATIENTS, element: <PatientsListPage />, audience: [ AccountType.HealthProfessional ]},

        { path: SharedUrls.LABTEST_PACKAGES, element: <DiagnosticTestsPackagesExplorerPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},

        { path: ShehrdUrls.CREATE_UPLOADCODE, element: <CreateUploadCodePage />, audience: [ AccountType.Sharer ]},
        { path: SharedUrls.EXPORT_PATIENTDATA, element: <ExportPatientDataPage />, audience: [ AccountType.Researcher ]},

        { path: SharedUrls.CREATE_APPOINTMENT, element: <CreateEditAppointmentPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: SharedUrls.EDIT_APPOINTMENT, element: <CreateEditAppointmentPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: SharedUrls.INSTITUTION_DEPARTMENT_CREATE_APPOINTMENT, element: <CreateEditAppointmentPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: SharedUrls.APPOINTMENTS, element: <MyAppointmentsPage />, audience: [ AccountType.Sharer ]},
        { path: SharedUrls.APPOINTMENTS, element: <AppointmentsListPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: SharedUrls.PRINT_APPOINTMENT, element: <PrintAppointmentPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: SharedUrls.ENCOUNTERS, element: <MyEncoutnersPage />, audience: [ AccountType.Sharer ]},

        { path: SharedUrls.PRINT_TEMPLATES, element: <PrintTemplateListPage />, audience: [ AccountType.Admin ] },
        { path: SharedUrls.CREATE_PRINTTEMPLATE, element: <PrintTemplateEditorPage />, audience: [ AccountType.Admin ] },
        { path: SharedUrls.EDIT_PRINTTEMPLATE, element: <PrintTemplateEditorPage />, audience: [ AccountType.Admin ] },

        { path: ShopUrls.CHECKOUT, element: <CheckoutPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: ShopUrls.EMERGENCY_CARD, element: <EmergencyCardOrderPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher ]},
        { path: ShopUrls.OPEN_ORDER, element: <OrderProgressPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: ShopUrls.ORDERS, element: <MyOrdersPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: ShopUrls.VOUCHERS, element: <VouchersListPage />, audience: [ AccountType.Admin ]},
        { path: ShopUrls.CREATE_VOUCHER, element: <CreateEditVoucherPage />, audience: [ AccountType.Admin ]},
        { path: ShopUrls.EDIT_VOUCHER, element: <CreateEditVoucherPage />, audience: [ AccountType.Admin ]},
        { path: ShopUrls.ITEMS, element: <ShopItemsAdminPage />, audience: [ AccountType.Admin ]},
        { path: ShopUrls.CREATE_ITEM, element: <CreateEditShopItemPage />, audience: [ AccountType.Admin ]},
        { path: ShopUrls.EDIT_ITEM, element: <CreateEditShopItemPage />, audience: [ AccountType.Admin ]},
        { path: ShopUrls.SHIPPINGMETHODS, element: <ShippingMethodsPage />, audience: [ AccountType.Admin ]},
        { path: ShopUrls.CREATE_SHIPPINGMETHOD, element: <CreateEditShippingMethodPage />, audience: [ AccountType.Admin ]},
        { path: ShopUrls.EDIT_SHIPPINGMETHOD, element: <CreateEditShippingMethodPage />, audience: [ AccountType.Admin ]},
        { path: ShopUrls.EMERGENCYCARD_QR_GENERATOR, element: <EmergencyCardQrCodeGeneratorPage />, audience: [ AccountType.Admin ]},

        { path: SharedUrls.EQUIPMENT_MEASUREMENTS , element: <EquipmentMeasurementsListPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: SharedUrls.CREATE_EQUIPMENT_MEASUREMENT, element: <CreateEditEquipmentMeasurement />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
        { path: SharedUrls.EDIT_EQUIPMENT_MEASUREMENT, element: <CreateEditEquipmentMeasurement />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},

        { path: SharedUrls.DRUGS, element: <DrugsListPage />, audience: [ AccountType.Pharmacy, AccountType.Admin ]}, // Other account types have access to route through HealthRecordRoutes
        { path: SharedUrls.CREATE_DRUG, element: <CreateEditDrugPage />, audience: [ AccountType.Pharmacy, AccountType.Admin ]}, // Other account types have access to route through HealthRecordRoutes
        { path: SharedUrls.EDIT_DRUG, element: <CreateEditDrugPage />, audience: [ AccountType.Pharmacy, AccountType.Admin ]}, // Other account types have access to route through HealthRecordRoutes
        { path: SharedUrls.PHARMACY_ORDERS, element: <PharmacyOrdersListPage />, audience: [ AccountType.Pharmacy ]},
        { path: SharedUrls.OPEN_PHARMACY_ORDER, element: <PharmacyOrderPage />, audience: [ AccountType.Pharmacy ]},
        { path: SharedUrls.CREATE_PHARMACY_ORDER, element: <CreateEditPharmacyOrder />, audience: [ AccountType.Pharmacy ]},
        { path: SharedUrls.EDIT_PHARMACY_ORDER, element: <CreateEditPharmacyOrder />, audience: [ AccountType.Pharmacy ]},

        { path: ShehrdUrls.EPIDEMIOLOGY_DASHBOARD, element: <EpidemiologyDashboardPage />, audience: [ AccountType.PublicHealthAgency] },
        { path: ShehrdUrls.PUBLICHEALTHAGENCY_SETTINGS, element: <PublicHealthAgencySettingsPage />, audience: [ AccountType.PublicHealthAgency] },
        { path: ShehrdUrls.PUBLICHEALTHAGENCIES, element: <PublicHealthAgencyAssociationPage />, audience: [ AccountType.Sharer ] },

        { path: ShehrdUrls.PUBLICDATAAPPROVAL, element: <PublicDataApprovalPage />, audience: [ AccountType.Admin] },
        { path: ToolsUrls.ISITBROKEN_CONFIGURATION, element: <IsItBrokenConfigurationPage />, audience: [ AccountType.Admin] },
        { path: SharedUrls.FEEDBACK, element: <FeedbackListPage />, audience: [ AccountType.Admin ]},

        { path: ShehrdUrls.CONNECT_CLOUD_STORAGE, element: <ConnectCloudStoragePage />, audience: [ AccountType.Sharer ]},
        { path: ShehrdUrls.DROPBOX_AUTHORIZATION, element: <DropboxAuthorizedPage />, audience: [ AccountType.Sharer ]},

        ...ToolsRoutes(features, onNewAuthenticationResult, onLogOut),
    ];
    if(features.enableTaskList) {
        const taskListRoutes: ShehrdRouteDefinition[] = [
            { path: SharedUrls.CREATE_TASK, element: <CreateEditTodoListItemPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: SharedUrls.EDIT_TASK, element: <CreateEditTodoListItemPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: SharedUrls.OPEN_TASK, element: <TaskPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: SharedUrls.TASK_PACKAGES, element: <TasksPackagesPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: SharedUrls.CREATE_TASKPACKAGE, element: <CreateEditTasksPackagePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: SharedUrls.EDIT_TASKPACKAGE, element: <CreateEditTasksPackagePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: SharedUrls.DEPLOY_TASKPACKAGE, element: <DeployTasksPackagePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},
            { path: SharedUrls.TASKPACKAGE_DEPLOY, element: <DeployTasksPackagePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},

            { path: SharedUrls.PRINT_TODOLIST, element: <PrintTaskListPage />, usesCustomLayout: true, audience: [ AccountType.HealthProfessional, AccountType.Researcher ]},

            ...ChecklistRoutes().map(route => ({
                ...route,
                audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy, AccountType.Admin ]
            }) as ShehrdRouteDefinition)
        ];
        routes.push(...taskListRoutes);
    }
    if(features.healthRecord.enableQuestionnaires) {
        const questionnaireRoutes: ShehrdRouteDefinition[] = [
            { path: SharedUrls.QUESTIONNAIRES, element: <QuestionnairesListPage />, audience: [ AccountType.Sharer, AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ]},
            { path: SharedUrls.CREATE_QUESTIONNAIRE, element: <CreateEditQuestionnairePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ] },
            { path: SharedUrls.EDIT_QUESTIONNAIRE, element: <CreateEditQuestionnairePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Admin ] },
        ];
        routes.push(...questionnaireRoutes);
    }
    if(features.enableServices) {
        // const serviceRoutes: ShehrdRouteDefinition[] = [
        //     { path: SharedUrls.SERVICES, element: <ServicesListPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        //     { path: SharedUrls.CREATE_SERVICE, element: <CreateEditServicePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        //     { path: SharedUrls.EDIT_SERVICE, element: <CreateEditServicePage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        //     { path: SharedUrls.SERVICEREQUESTS, element: <ServiceRequestsListPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        //     { path: SharedUrls.CREATE_SERVICEREQUEST, element: <CreateEditServiceRequestPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        //     { path: SharedUrls.EDIT_SERVICEREQUEST, element: <CreateEditServiceRequestPage />, audience: [ AccountType.HealthProfessional, AccountType.Researcher, AccountType.Pharmacy ]},
        // ];
        // routes.push(...serviceRoutes);
    }

    return routes;
}
export default RegisteredUserRoutes;