import { Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import { ToolsDropdownMenu } from './ToolsDropdownMenu';
import { SharedUrls } from '../../../sharedHealthComponents/navigation/Urls';
import { ShehrdUrls, ShopUrls, ToolsUrls } from '../../navigation/Urls';
import { ChecklistUrls } from '../../../checklist/navigation/Urls';

interface AdminMenuProps {}

export const AdminMenu = (props: AdminMenuProps) => {

    const navigate = useNavigate();
    return (
        <Nav>
            <NavDropdown title={resolveText("Menu_AdminActions")}>
                <NavDropdown.Item onClick={() => navigate(ShehrdUrls.ACCOUNTS)}>{resolveText("Menu_Accounts")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.INSTITUTIONS)}>{resolveText("Menu_Institutions")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShehrdUrls.STUDIES)}>{resolveText("Menu_Studies")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ChecklistUrls.CHECKLISTS)}>{resolveText("Menu_Checklists")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.QUESTIONNAIRES)}>{resolveText("Menu_Questionnaires")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.EQUIPMENT_MEASUREMENTS)}>{resolveText("Menu_EquipmentMeasurements")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.DRUGS)}>{resolveText("Menu_Drugs")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.PRINT_TEMPLATES)}>{resolveText("Menu_PrintTemplates")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShehrdUrls.PUBLICDATAAPPROVAL)}>{resolveText("Menu_PublicDataApproval")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ToolsUrls.ISITBROKEN_CONFIGURATION)}>{resolveText("Menu_IsItBrokenConfiguration")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.FEEDBACK)}>{resolveText("Menu_Feedback")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={resolveText("Menu_Billing")}>
                <NavDropdown.Item onClick={() => navigate(ShopUrls.ORDERS)}>{resolveText("Menu_Orders")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShopUrls.VOUCHERS)}>{resolveText("Menu_Vouchers")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShopUrls.SHIPPINGMETHODS)}>{resolveText("Menu_ShippingMethods")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShopUrls.ITEMS)}>{resolveText("Menu_Shopitems")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShopUrls.EMERGENCYCARD_QR_GENERATOR)}>Emergency card QR generator</NavDropdown.Item>
            </NavDropdown>
            <ToolsDropdownMenu />
        </Nav>
    );

}