import { lazy } from 'react';
import { Models } from '../../localComponents/types/models';
import { RouteDefinition } from '../../sharedCommonComponents/types/frontendTypes';
import { HealthRecordEntryType } from '../../localComponents/types/enums';
import { HealthRecordUrls } from './Urls';


const EditPersonPage = lazy(() => import('../../sharedHealthComponents/pages/Patients/EditPersonPage'));
const CreateEditMedicalAlertPage = lazy(() => import('../pages/Patients/CreateEditMedicalAlertPage'));
const CreateEditHealthProfessionalOpinionPage = lazy(() => import('../pages/CreateEditHealthProfessionalOpinionPage'));
const DiagnosisWizardPage = lazy(() => import('../pages/Diagnoses/DiagnosisWizardPage'));
const AddImmunizationPage = lazy(() => import('../pages/Medication/AddImmunizationPage'));
const AddMedicationPage = lazy(() => import('../pages/Medication/AddMedicationPage'));
const CreatePatientObservationPage = lazy(() => import('../pages/Observations/CreatePatientObservationPage'));
const AnswerQuestionnairePage = lazy(() => import('../pages/Patients/AnswerQuestionnairePage'));
const AssignQuestionnairePage = lazy(() => import('../pages/Patients/AssignQuestionnairePage'));
const CreateAllergyPage = lazy(() => import('../pages/Patients/CreateAllergyPage'));
const CreateDiagnosisPage = lazy(() => import('../pages/Patients/CreateDiagnosisPage'));
const CreateEditEncounterPage = lazy(() => import('../pages/Patients/CreateEditEncounterPage'));
const CreateEditEquipmentPage = lazy(() => import('../pages/Patients/CreateEditEquipmentPage'));
const CreateEditSpecimenPage = lazy(() => import('../pages/Patients/CreateEditSpecimenPage'));
const CreateMedicalProcedurePage = lazy(() => import('../pages/Patients/CreateMedicalProcedurePage'));
const CreatePatientDocumentPage = lazy(() => import('../pages/Patients/CreatePatientDocumentPage'));
const CreatePatientNotePage = lazy(() => import('../pages/Notes/CreatePatientNotePage'));
const ComplexPatientNoteViewerPage = lazy(() => import('../pages/Notes/ComplexPatientNoteViewerPage'));
const ComplexPatientNoteComparisonPage = lazy(() => import('../pages/Notes/ComplexPatientNoteComparisonPage'));
const ImagingUploadPage = lazy(() => import('../pages/Patients/ImagingUploadPage'));
const PatientMedicationsPage = lazy(() => import('../pages/Patients/PatientMedicationsPage'));
const PatientTimelinePage = lazy(() => import('../pages/Patients/PatientTimelinePage'));
const CreatePatientTestResultPage = lazy(() => import('../pages/TestResults/CreatePatientTestResultPage'));
const EditTestResultPage = lazy(() => import('../pages/TestResults/EditTestResultPage'));
const GenomeExplorationPage = lazy(() => import('../pages/TestResults/GenomeExplorationPage'));
const GenomeUploadPage = lazy(() => import('../pages/TestResults/GenomeUploadPage'));
const ImagingExplorationPage = lazy(() => import('../pages/TestResults/ImagingExplorationPage'));
const CreateEditPregnancyPage = lazy(() => import('../pages/Patients/CreateEditPregnancyPage'));
const EditQuestionnaireAnswersRepetitionPage = lazy(() => import('../pages/Questionnaires/EditQuestionnaireAnswersRepetitionPage'));
const LooseQuestionsAskingPage = lazy(() => import('../pages/Questionnaires/LooseQuestionsAskingPage'));
const CreateEditMedicationSchedulePage = lazy(() => import('../pages/Patients/CreateEditMedicationSchedulePage'));
const CreateEditMedicationDispensionPage = lazy(() => import('../pages/Medication/CreateEditMedicationDispensionPage'));
const PrintHealthRecordPage = lazy(() => import('../pages/Patients/PrintHealthRecordPage'));
const CprPage = lazy(() => import('../pages/Patients/CprPage'));
const WorkupPage = lazy(() => import('../pages/TreatmentProcesses/WorkupPage'));
const SurgeryPage = lazy(() => import('../pages/TreatmentProcesses/SurgeryPage'));

export const HealthRecordRoutes = (features: Models.Configuration.HealthRecordFeatureSettings): RouteDefinition[] => {

    const healthRecordFeatures = features;
    const routes: RouteDefinition[] = [
        { path: HealthRecordUrls.EDIT_PERSON, element: <EditPersonPage />},
        { path: HealthRecordUrls.TIMELINE, element: <PatientTimelinePage />},
        { path: HealthRecordUrls.PRINT_HEALTHRECORD, usesCustomLayout: true, element: <PrintHealthRecordPage />},

        // Encounter
        { path: HealthRecordUrls.CREATE_ENCOUNTER, element: <CreateEditEncounterPage />},
        { path: HealthRecordUrls.EDIT_ENCOUNTER, element: <CreateEditEncounterPage />},

        // CPR
        { path: HealthRecordUrls.NEW_CPR, usesCustomLayout: true, element: <CprPage />},
        { path: HealthRecordUrls.OPEN_CPR, usesCustomLayout: true, element: <CprPage />}
    ];
    if(healthRecordFeatures.enableDiagnoses) {
        const diagnosisRoutes: RouteDefinition[] = [
            { path: `wizard/${HealthRecordEntryType.Diagnosis}`, element: <DiagnosisWizardPage /> },

            { path: HealthRecordUrls.CREATE_DIAGNOSIS, element: <CreateDiagnosisPage />},
            { path: HealthRecordUrls.EDIT_DIAGNOSIS, element: <CreateDiagnosisPage />},

            { path: HealthRecordUrls.CREATE_ALLERGY, element: <CreateAllergyPage />},
            { path: HealthRecordUrls.EDIT_ALLERGY, element: <CreateAllergyPage />},

            { path: HealthRecordUrls.CREATE_MEDICALALERT, element: <CreateEditMedicalAlertPage />},
            { path: HealthRecordUrls.EDIT_MEDICALALERT, element: <CreateEditMedicalAlertPage />},

            { path: HealthRecordUrls.CREATE_WORKUP, element: <WorkupPage />},
            { path: HealthRecordUrls.EDIT_WORKUP, element: <WorkupPage />},

            { path: HealthRecordUrls.CREATE_SURGERY, element: <SurgeryPage />},
            { path: HealthRecordUrls.EDIT_SURGERY, element: <SurgeryPage />},
        ]
        routes.push(...diagnosisRoutes);
    }
    if(healthRecordFeatures.enableDocuments) {
        const documentRoutes: RouteDefinition[] = [
            { path: HealthRecordUrls.CREATE_DOCUMENT, element: <CreatePatientDocumentPage />},
            { path: HealthRecordUrls.EDIT_DOCUMENT, element: <CreatePatientDocumentPage />},
        ];
        routes.push(...documentRoutes);
    }
    if(healthRecordFeatures.enableEquipment) {
        const equipmentRoutes: RouteDefinition[] = [
            { path: HealthRecordUrls.CREATE_EQUIPMENT, element: <CreateEditEquipmentPage />},
            { path: HealthRecordUrls.EDIT_EQUIPMENT, element: <CreateEditEquipmentPage />},
        ];
        routes.push(...equipmentRoutes);
    }
    if(healthRecordFeatures.enableMedication) {
        const medicationRoutes: RouteDefinition[] = [
            { path: HealthRecordUrls.MEDICATIONS, element: <PatientMedicationsPage />},

            { path: HealthRecordUrls.CREATE_MEDICATIONDISPENSION, element: <AddMedicationPage />},
            { path: HealthRecordUrls.EDIT_MEDICATIONDISPENSION, element: <CreateEditMedicationDispensionPage />},

            { path: HealthRecordUrls.CREATE_IMMUNIZATION, element: <AddImmunizationPage />},
            { path: HealthRecordUrls.EDIT_IMMUNIZATION, element: <AddImmunizationPage />},

            { path: HealthRecordUrls.CREATE_MEDICATIONSCHEDULE, element: <CreateEditMedicationSchedulePage />},
            { path: HealthRecordUrls.EDIT_MEDICATIONSCHEDULE, element: <CreateEditMedicationSchedulePage />},
        ];
        routes.push(...medicationRoutes);
    }
    if(healthRecordFeatures.enableNotes) {
        const noteRoutes: RouteDefinition[] = [
            { path: HealthRecordUrls.CREATE_NOTE, element: <CreatePatientNotePage />},
            { path: HealthRecordUrls.EDIT_NOTE, element: <CreatePatientNotePage />},
            { path: HealthRecordUrls.VIEW_NOTE, element: <ComplexPatientNoteViewerPage />},
            { path: HealthRecordUrls.COMPARE_NOTES, element: <ComplexPatientNoteComparisonPage />},
        ];
        routes.push(...noteRoutes);
    }
    if(healthRecordFeatures.enableObservations) {
        const observationRoutes: RouteDefinition[] = [
            { path: HealthRecordUrls.CREATE_OBSERVATION, element: <CreatePatientObservationPage />},
            { path: HealthRecordUrls.EDIT_OBSERVATION, element: <EditTestResultPage />},
        ];
        routes.push(...observationRoutes);
    }
    if(healthRecordFeatures.enableOpinions) {
        const opinionRoutes: RouteDefinition[] = [
            { path: HealthRecordUrls.CREATE_OPINION, element: <CreateEditHealthProfessionalOpinionPage />},
            { path: HealthRecordUrls.EDIT_OPINION, element: <CreateEditHealthProfessionalOpinionPage />},
        ];
        routes.push(...opinionRoutes);
    }
    if(healthRecordFeatures.enablePregnancies) {
        const pregnancyRoutes: RouteDefinition[] = [
            { path: HealthRecordUrls.CREATE_PREGNANCY, element: <CreateEditPregnancyPage />},
            { path: HealthRecordUrls.EDIT_PREGNANCY, element: <CreateEditPregnancyPage />},
        ];
        routes.push(...pregnancyRoutes);
    }
    if(healthRecordFeatures.enableProcedures) {
        const procedureRoutes: RouteDefinition[] = [
            { path: HealthRecordUrls.CREATE_PROCEDURE, element: <CreateMedicalProcedurePage />},
            { path: HealthRecordUrls.EDIT_PROCEDURE, element: <CreateMedicalProcedurePage />},
        ];
        routes.push(...procedureRoutes);
    }
    if(healthRecordFeatures.enableQuestionnaires) {
        const questionnaireRoutes: RouteDefinition[] = [
            { path: HealthRecordUrls.QUESTIONS, element: <LooseQuestionsAskingPage />},
            { path: HealthRecordUrls.CREATE_QUESTIONNAIRE, element: <AssignQuestionnairePage />},
            { path: HealthRecordUrls.ANSWER_QUESTIONNAIRE, element: <AnswerQuestionnairePage />},
            { path: HealthRecordUrls.EDIT_QUESTIONNAIREANSWER, element: <AnswerQuestionnairePage />},
            { path: HealthRecordUrls.CREATE_QUESTIONNAIRESERIES, element: <EditQuestionnaireAnswersRepetitionPage />},
            { path: HealthRecordUrls.EDIT_QUESTIONNAIRESERIES, element: <EditQuestionnaireAnswersRepetitionPage />},
        ];
        routes.push(...questionnaireRoutes);
    }
    if(healthRecordFeatures.enableSpecimens) {
        const specimenRoutes: RouteDefinition[] = [
            { path: HealthRecordUrls.CREATE_SPECIMEN, element: <CreateEditSpecimenPage />},
            { path: HealthRecordUrls.EDIT_SPECIMEN, element: <CreateEditSpecimenPage />},
        ];
        routes.push(...specimenRoutes);
    }
    if(healthRecordFeatures.enableTestResults) {
        const testResultRoutes: RouteDefinition[] = [
            { path: HealthRecordUrls.CREATE_TESTRESULT, element: <CreatePatientTestResultPage />},
            { path: HealthRecordUrls.EDIT_TESTRESULT, element: <EditTestResultPage />},

            // Special test results
            { path: HealthRecordUrls.UPLOAD_IMAGING, element: <ImagingUploadPage />},
            { path: HealthRecordUrls.OPEN_IMAGINGSTUDY, element: <ImagingExplorationPage />},
            
            { path: HealthRecordUrls.UPLOAD_GENOME, element: <GenomeUploadPage />},
            { path: HealthRecordUrls.OPEN_GENOMEEXPLORER, element: <GenomeExplorationPage />},
        ];
        routes.push(...testResultRoutes);
    }
    return routes;
}
export default HealthRecordRoutes;