import { addDays, differenceInDays } from "date-fns";
import { Models } from "../../localComponents/types/models";

export const isPregnancyPersonId = (personId: string) => {
    return personId.includes('|');
}

export const getGestationAge = (pregnancy: Models.ObGyn.Pregnancy) => {
    if(!pregnancy.lastPeriod && !(pregnancy.conceptionDate && pregnancy.averageCycleLengthInDaysBeforeConception)) {
        return null;
    }
    const lastMenstruationDate = pregnancy.lastPeriod 
        ? new Date(pregnancy.lastPeriod) 
        : addDays(new Date(pregnancy.conceptionDate!), pregnancy.averageCycleLengthInDaysBeforeConception! / 2);
    const endDateOrNow = pregnancy.endDate ? new Date(pregnancy.endDate) : new Date();
    const gestationAgeInDays = differenceInDays(endDateOrNow, lastMenstruationDate);
    const weeks = Math.floor(gestationAgeInDays / 7);
    const days = gestationAgeInDays - weeks * 7;

    return `${weeks}+${days}`;
}
export const calculateGestationalAgeAtDate = (
    gestationalAgeAtBirth: Models.ObGyn.GestationalAge | null | undefined, 
    birthDate: Date, 
    currentDate: Date): Models.ObGyn.GestationalAge | undefined => {

    if(!gestationalAgeAtBirth || gestationalAgeAtBirth.weeks === undefined || gestationalAgeAtBirth.days === undefined) {
        return undefined;
    }
    const gestationalAgeAtBirthInDays = 7 * gestationalAgeAtBirth.weeks + gestationalAgeAtBirth.days;
    const ageInDays = differenceInDays(currentDate, birthDate);
    const totalDays = gestationalAgeAtBirthInDays + ageInDays;
    const weeks = Math.floor(totalDays / 7);
    const days = Math.floor(totalDays - 7 * weeks);
    return {
        type: gestationalAgeAtBirth.type,
        weeks: weeks,
        days: days
    };
}