export enum AddressRole {
    Primary = "Primary",
    Secondary = "Secondary",
    Temporary = "Temporary"
}
export enum AdverseEventSeverity {
    Unknown = "Unknown",
    NearMiss = "NearMiss",
    ReachedPatientButNoHarm = "ReachedPatientButNoHarm",
    Nuisance = "Nuisance",
    RequiringUrgentCare = "RequiringUrgentCare",
    RequiringHospitalization = "RequiringHospitalization",
    HospitalizationProlonging = "HospitalizationProlonging",
    RequiringIntensiveCare = "RequiringIntensiveCare",
    LifeThreatening = "LifeThreatening",
    CausedPermanentDamage = "CausedPermanentDamage",
    Death = "Death"
}
export enum AutoCompleteContext {
    MeasurementType = "MeasurementType",
    Unit = "Unit",
    DrugBrand = "DrugBrand",
    DrugActiveIngredient = "DrugActiveIngredient",
    DrugAdministrationRoute = "DrugAdministrationRoute",
    DrugDispensionForm = "DrugDispensionForm",
    ResourceGroup = "ResourceGroup",
    ExternalLocation = "ExternalLocation",
    ImmunizationPathogen = "ImmunizationPathogen",
    ContactPersonRole = "ContactPersonRole",
    EquipmentType = "EquipmentType",
    TodoListTag = "TodoListTag",
    AdverseEventTitle = "AdverseEventTitle",
    IsolationReason = "IsolationReason",
    IsolationPrecaution = "IsolationPrecaution",
    MealIngredients = "MealIngredients",
    DietaryCharacteristics = "DietaryCharacteristics",
    SymptomName = "SymptomName",
    QuestionText = "QuestionText",
    SpecimenBodyPart = "SpecimenBodyPart",
    SpecimenCollectionMethod = "SpecimenCollectionMethod",
    SpecimenContainerType = "SpecimenContainerType",
    Diagnosis = "Diagnosis",
    Allergy = "Allergy",
    MedicalProcedure = "MedicalProcedure",
    MedicalProcedureBodyPart = "MedicalProcedureBodyPart",
    KnowledgeBaseCategory = "KnowledgeBaseCategory",
    DiagnosticTestName = "DiagnosticTestName"
}
export enum BodySide {
    Front = "Front",
    Back = "Back"
}
export enum ClassificationType {
    Undefined = "Undefined",
    SnomedCt = "SnomedCt",
    Icd11 = "Icd11",
    Loinc = "Loinc",
    Umls = "Umls",
    OrphaCodes = "OrphaCodes"
}
export enum Country {
    af = "af",
    al = "al",
    dz = "dz",
    as = "as",
    ad = "ad",
    ao = "ao",
    ai = "ai",
    aq = "aq",
    ag = "ag",
    ar = "ar",
    am = "am",
    aw = "aw",
    au = "au",
    at = "at",
    az = "az",
    bs = "bs",
    bh = "bh",
    bd = "bd",
    bb = "bb",
    by = "by",
    be = "be",
    bz = "bz",
    bj = "bj",
    bm = "bm",
    bt = "bt",
    bo = "bo",
    bq = "bq",
    ba = "ba",
    bw = "bw",
    bv = "bv",
    br = "br",
    io = "io",
    bn = "bn",
    bg = "bg",
    bf = "bf",
    bi = "bi",
    kh = "kh",
    cm = "cm",
    ca = "ca",
    cv = "cv",
    ky = "ky",
    cf = "cf",
    td = "td",
    cl = "cl",
    cn = "cn",
    cx = "cx",
    cc = "cc",
    co = "co",
    km = "km",
    cg = "cg",
    cd = "cd",
    ck = "ck",
    cr = "cr",
    hr = "hr",
    cu = "cu",
    cw = "cw",
    cy = "cy",
    cz = "cz",
    ci = "ci",
    dk = "dk",
    dj = "dj",
    dm = "dm",
    do = "do",
    ec = "ec",
    eg = "eg",
    sv = "sv",
    gq = "gq",
    er = "er",
    ee = "ee",
    et = "et",
    fk = "fk",
    fo = "fo",
    fj = "fj",
    fi = "fi",
    fr = "fr",
    gf = "gf",
    pf = "pf",
    tf = "tf",
    ga = "ga",
    gm = "gm",
    ge = "ge",
    de = "de",
    gh = "gh",
    gi = "gi",
    gr = "gr",
    gl = "gl",
    gd = "gd",
    gp = "gp",
    gu = "gu",
    gt = "gt",
    gg = "gg",
    gn = "gn",
    gw = "gw",
    gy = "gy",
    ht = "ht",
    hm = "hm",
    va = "va",
    hn = "hn",
    hk = "hk",
    hu = "hu",
    is = "is",
    in = "in",
    id = "id",
    ir = "ir",
    iq = "iq",
    ie = "ie",
    im = "im",
    il = "il",
    it = "it",
    jm = "jm",
    jp = "jp",
    je = "je",
    jo = "jo",
    kz = "kz",
    ke = "ke",
    ki = "ki",
    kp = "kp",
    kr = "kr",
    kw = "kw",
    kg = "kg",
    la = "la",
    lv = "lv",
    lb = "lb",
    ls = "ls",
    lr = "lr",
    ly = "ly",
    li = "li",
    lt = "lt",
    lu = "lu",
    mo = "mo",
    mk = "mk",
    mg = "mg",
    mw = "mw",
    my = "my",
    mv = "mv",
    ml = "ml",
    mt = "mt",
    mh = "mh",
    mq = "mq",
    mr = "mr",
    mu = "mu",
    yt = "yt",
    mx = "mx",
    fm = "fm",
    md = "md",
    mc = "mc",
    mn = "mn",
    me = "me",
    ms = "ms",
    ma = "ma",
    mz = "mz",
    mm = "mm",
    na = "na",
    nr = "nr",
    np = "np",
    nl = "nl",
    nc = "nc",
    nz = "nz",
    ni = "ni",
    ne = "ne",
    ng = "ng",
    nu = "nu",
    nf = "nf",
    mp = "mp",
    no = "no",
    om = "om",
    pk = "pk",
    pw = "pw",
    ps = "ps",
    pa = "pa",
    pg = "pg",
    py = "py",
    pe = "pe",
    ph = "ph",
    pn = "pn",
    pl = "pl",
    pt = "pt",
    pr = "pr",
    qa = "qa",
    ro = "ro",
    ru = "ru",
    rw = "rw",
    re = "re",
    bl = "bl",
    sh = "sh",
    kn = "kn",
    lc = "lc",
    mf = "mf",
    pm = "pm",
    vc = "vc",
    ws = "ws",
    sm = "sm",
    st = "st",
    sa = "sa",
    sn = "sn",
    rs = "rs",
    sc = "sc",
    sl = "sl",
    sg = "sg",
    sx = "sx",
    sk = "sk",
    si = "si",
    sb = "sb",
    so = "so",
    za = "za",
    gs = "gs",
    ss = "ss",
    es = "es",
    lk = "lk",
    sd = "sd",
    sr = "sr",
    sj = "sj",
    sz = "sz",
    se = "se",
    ch = "ch",
    sy = "sy",
    tw = "tw",
    tj = "tj",
    tz = "tz",
    th = "th",
    tl = "tl",
    tg = "tg",
    tk = "tk",
    to = "to",
    tt = "tt",
    tn = "tn",
    tr = "tr",
    tm = "tm",
    tc = "tc",
    tv = "tv",
    ug = "ug",
    ua = "ua",
    ae = "ae",
    gb = "gb",
    us = "us",
    um = "um",
    uy = "uy",
    uz = "uz",
    vu = "vu",
    ve = "ve",
    vn = "vn",
    vg = "vg",
    vi = "vi",
    wf = "wf",
    eh = "eh",
    ye = "ye",
    zm = "zm",
    zw = "zw",
    ax = "ax"
}
export enum HealthRecordEntryType {
    Undefined = "Undefined",
    Note = "Note",
    TestResult = "TestResult",
    Document = "Document",
    MedicationDispension = "MedicationDispension",
    Equipment = "Equipment",
    Diagnosis = "Diagnosis",
    Questionnaire = "Questionnaire",
    Procedure = "Procedure",
    Immunization = "Immunization",
    Allergy = "Allergy",
    HealthProfessionalOpinion = "HealthProfessionalOpinion",
    Specimen = "Specimen",
    Encounter = "Encounter",
    Pregnancy = "Pregnancy",
    Infusion = "Infusion",
    Isolation = "Isolation",
    Prescription = "Prescription",
    MedicationSchedule = "MedicationSchedule",
    Process = "Process",
    MedicalAlert = "MedicalAlert",
    Question = "Question",
    FamilyTree = "FamilyTree",
    HealthRecordSummary = "HealthRecordSummary"
}
export enum LegalEntityType {
    Unknown = "Unknown",
    Person = "Person",
    Practitioner = "Practitioner",
    Institution = "Institution",
    Guest = "Guest"
}
export enum Month {
    January = "January",
    February = "February",
    March = "March",
    April = "April",
    May = "May",
    June = "June",
    July = "July",
    August = "August",
    September = "September",
    October = "October",
    November = "November",
    December = "December"
}
export enum NumericComparisonType {
    Equal = "Equal",
    GreaterThan = "GreaterThan",
    GreaterThanOrEqual = "GreaterThanOrEqual",
    LessThan = "LessThan",
    LessThanOrEqual = "LessThanOrEqual"
}
export enum OrderDirection {
    Ascending = "Ascending",
    Descending = "Descending"
}
export enum PrintTemplateType {
    Other = "Other",
    Prescription = "Prescription",
    Appointment = "Appointment"
}
export enum PrintTemplateItemType {
    FreeText = "FreeText",
    PersonId = "PersonId",
    PersonFirstName = "PersonFirstName",
    PersonLastName = "PersonLastName",
    PersonFullName = "PersonFullName",
    PersonBirthDate = "PersonBirthDate",
    MedicationProductName = "MedicationProductName",
    MedicationBrand = "MedicationBrand",
    MedicationDosage = "MedicationDosage",
    MedicationInstructions = "MedicationInstructions",
    PractitionerName = "PractitionerName",
    PractitionerPersonId = "PractitionerPersonId",
    AppointmentStartTime = "AppointmentStartTime",
    AppointmentEndTime = "AppointmentEndTime",
    Note = "Note",
    AppointmentType = "AppointmentType",
    CreatedTimestamp = "CreatedTimestamp",
    Date = "Date",
    DateTime = "DateTime",
    Institution = "Institution",
    Department = "Department"
}
export enum StorageOperation {
    Undefined = "Undefined",
    Created = "Created",
    Changed = "Changed",
    Deleted = "Deleted"
}
export enum VerificationStatus {
    Unconfirmed = "Unconfirmed",
    Confirmed = "Confirmed",
    Refuted = "Refuted"
}
export enum ServiceAudienceType {
    All = "All",
    Role = "Role",
    Person = "Person",
    Institution = "Institution",
    Department = "Department"
}
export enum ServicePackageType {
    General = "General",
    MealMenu = "MealMenu"
}
export enum ServiceParameterValueType {
    Text = "Text",
    Boolean = "Boolean",
    Number = "Number",
    Date = "Date",
    Patient = "Patient",
    Option = "Option"
}
export enum ServiceRequestState {
    Requested = "Requested",
    CancelledByRequester = "CancelledByRequester",
    Declined = "Declined",
    Accepted = "Accepted",
    ReadyWhenYouAre = "ReadyWhenYouAre",
    InProgress = "InProgress",
    Fulfilled = "Fulfilled"
}
export enum ServiceType {
    General = "General",
    Meal = "Meal",
    DiagnosticTest = "DiagnosticTest",
    Hospitalization = "Hospitalization"
}
export enum AppointmentType {
    Undefined = "Undefined",
    Walkin = "Walkin",
    Checkup = "Checkup",
    FollowUp = "FollowUp",
    Emergency = "Emergency"
}
export enum EncounterType {
    Undefined = "Undefined",
    Consultation = "Consultation",
    HomeVisit = "HomeVisit",
    Admission = "Admission",
    Virtual = "Virtual"
}
export enum SchedulePatternType {
    Single = "Single",
    Hourly = "Hourly",
    Daily = "Daily",
    Weekly = "Weekly",
    Monthly = "Monthly",
    Yearly = "Yearly"
}
export enum TimeslotGeneratorType {
    Single = "Single",
    Daily = "Daily",
    Weekly = "Weekly"
}
export enum TriagePriority {
    Lost = "Lost",
    Resuscitation = "Resuscitation",
    Emergency = "Emergency",
    Urgent = "Urgent",
    SemiUrgent = "SemiUrgent",
    NonUrgent = "NonUrgent"
}
export enum ConditionType {
    Infection = "Infection",
    ExternalCause = "ExternalCause",
    Cancer = "Cancer",
    Pregnancy = "Pregnancy",
    MentalHealth = "MentalHealth",
    Speech = "Speech",
    Sleep = "Sleep",
    MetabolicDisorder = "MetabolicDisorder",
    GeneticDisorder = "GeneticDisorder",
    Other = "Other"
}
export enum InjuryType {
    Mechanical = "Mechanical",
    Thermal = "Thermal",
    Chemical = "Chemical",
    Poisoning = "Poisoning",
    MedicalIntervention = "MedicalIntervention",
    Other = "Other"
}
export enum Organ {
    NervousSystem = "NervousSystem",
    Brain = "Brain",
    Spine = "Spine",
    Eyes = "Eyes",
    Ears = "Ears",
    Head = "Head",
    Jaw = "Jaw",
    Nose = "Nose",
    Heart = "Heart",
    VascularSystem = "VascularSystem",
    Blood = "Blood",
    ImmuneSystem = "ImmuneSystem",
    Spleen = "Spleen",
    Skin = "Skin",
    RespiratorySystem = "RespiratorySystem",
    Larynx = "Larynx",
    Thorax = "Thorax",
    Lungs = "Lungs",
    DigestiveSystem = "DigestiveSystem",
    OralCavity = "OralCavity",
    Pharynx = "Pharynx",
    Oesophagus = "Oesophagus",
    Stomach = "Stomach",
    SmallBowel = "SmallBowel",
    Liver = "Liver",
    BiliarySystem = "BiliarySystem",
    Pancreas = "Pancreas",
    Colon = "Colon",
    Rectum = "Rectum",
    Anus = "Anus",
    Retroperitoneum = "Retroperitoneum",
    UrinaryTract = "UrinaryTract",
    Pelvis = "Pelvis",
    Genitalia = "Genitalia",
    Breast = "Breast",
    Musculoskeletal = "Musculoskeletal",
    Bone = "Bone",
    Muscle = "Muscle",
    Limbs = "Limbs",
    EndocrineSystem = "EndocrineSystem",
    ThyroidGland = "ThyroidGland",
    AdrenalGland = "AdrenalGland",
    Pituitary = "Pituitary",
    FatTissue = "FatTissue"
}
export enum JsonPatchOperation {
    Add = "Add",
    Remove = "Remove",
    Replace = "Replace",
    Copy = "Copy",
    Move = "Move"
}
export enum MedicalProcessType {
    Undefined = "Undefined",
    Workup = "Workup",
    Surgery = "Surgery"
}
export enum MedicalProcedureOutcome {
    Successful = "Successful",
    Unsuccessful = "Unsuccessful",
    PartiallySuccessful = "PartiallySuccessful"
}
export enum DepartmentType {
    Other = "Other",
    Ward = "Ward",
    Diagnostics = "Diagnostics",
    Services = "Services",
    MealService = "MealService",
    Registration = "Registration",
    Clinics = "Clinics"
}
export enum GestationalAgeType {
    SinceLastMenstruation = "SinceLastMenstruation",
    SinceConception = "SinceConception"
}
export enum PregnancyStatus {
    Unknown = "Unknown",
    InProgress = "InProgress",
    Born = "Born",
    Lost = "Lost"
}
export enum PatientNoteSectionPartType {
    Freetext = "Freetext",
    Table = "Table",
    Image = "Image",
    Chart = "Chart"
}
export enum PatientNoteSectionType {
    Freetext = "Freetext",
    Diagnoses = "Diagnoses",
    TestResults = "TestResults"
}
export enum PatientNoteStatus {
    Draft = "Draft",
    Published = "Published",
    Archived = "Archived"
}
export enum PatientNoteType {
    Simple = "Simple",
    Complex = "Complex"
}
export enum DrugType {
    Unknown = "Unknown",
    Immunization = "Immunization"
}
export enum MedicationDispensionState {
    Undefined = "Undefined",
    Scheduled = "Scheduled",
    Dispensed = "Dispensed",
    Missed = "Missed",
    Skipped = "Skipped",
    Dispensing = "Dispensing"
}
export enum MedicationPackageSizeType {
    Unknown = "Unknown",
    Tablets = "Tablets",
    Volume = "Volume",
    Freetext = "Freetext"
}
export enum MedicationSchedulePatternType {
    PillCount = "PillCount",
    Amount = "Amount"
}
export enum MedicalTextPartType {
    Text = "Text",
    Disease = "Disease",
    Abbreviation = "Abbreviation",
    SnomedCtConcept = "SnomedCtConcept"
}
export enum MedicalTextPlaceholderType {
    FreeText = "FreeText",
    Disease = "Disease",
    SnomedCtConcept = "SnomedCtConcept",
    Patient = "Patient",
    Date = "Date",
    Signature = "Signature"
}
export enum MedicalTextSpecialWordType {
    NotSpecial = "NotSpecial",
    Template = "Template",
    Abbreviation = "Abbreviation",
    DiseasePlaceholder = "DiseasePlaceholder",
    SnomedCtPlaceholder = "SnomedCtPlaceholder",
    PatientPlaceholder = "PatientPlaceholder"
}
export enum MedicalTextTemplatePartType {
    Text = "Text",
    Abbreviation = "Abbreviation",
    Placeholder = "Placeholder"
}
export enum SharedNotificationType {
    NewPatientEvent = "NewPatientEvent",
    NewAdmission = "NewAdmission",
    NewAppointment = "NewAppointment",
    EncounterStatusChanged = "EncounterStatusChanged",
    NewService = "NewService",
    NewServiceRequest = "NewServiceRequest",
    ServiceRequestStateChange = "ServiceRequestStateChange"
}
export enum SharedSubscriptionType {
    Patient = "Patient",
    Institution = "Institution",
    Department = "Department",
    Service = "Service",
    ServiceRequest = "ServiceRequest"
}
export enum Language {
    ab = "ab",
    aa = "aa",
    af = "af",
    ak = "ak",
    sq = "sq",
    am = "am",
    ar = "ar",
    an = "an",
    hy = "hy",
    as = "as",
    av = "av",
    ae = "ae",
    ay = "ay",
    az = "az",
    bm = "bm",
    ba = "ba",
    eu = "eu",
    be = "be",
    bn = "bn",
    bi = "bi",
    bs = "bs",
    br = "br",
    bg = "bg",
    my = "my",
    ca = "ca",
    ch = "ch",
    ce = "ce",
    ny = "ny",
    zh = "zh",
    cu = "cu",
    cv = "cv",
    kw = "kw",
    co = "co",
    cr = "cr",
    hr = "hr",
    cs = "cs",
    da = "da",
    dv = "dv",
    nl = "nl",
    dz = "dz",
    en = "en",
    eo = "eo",
    et = "et",
    ee = "ee",
    fo = "fo",
    fj = "fj",
    fi = "fi",
    fr = "fr",
    fy = "fy",
    ff = "ff",
    gd = "gd",
    gl = "gl",
    lg = "lg",
    ka = "ka",
    de = "de",
    el = "el",
    kl = "kl",
    gn = "gn",
    gu = "gu",
    ht = "ht",
    ha = "ha",
    he = "he",
    hz = "hz",
    hi = "hi",
    ho = "ho",
    hu = "hu",
    is = "is",
    io = "io",
    ig = "ig",
    id = "id",
    ia = "ia",
    ie = "ie",
    iu = "iu",
    ik = "ik",
    ga = "ga",
    it = "it",
    ja = "ja",
    jv = "jv",
    kn = "kn",
    kr = "kr",
    ks = "ks",
    kk = "kk",
    km = "km",
    ki = "ki",
    rw = "rw",
    ky = "ky",
    kv = "kv",
    kg = "kg",
    ko = "ko",
    kj = "kj",
    ku = "ku",
    lo = "lo",
    la = "la",
    lv = "lv",
    li = "li",
    ln = "ln",
    lt = "lt",
    lu = "lu",
    lb = "lb",
    mk = "mk",
    mg = "mg",
    ms = "ms",
    ml = "ml",
    mt = "mt",
    gv = "gv",
    mi = "mi",
    mr = "mr",
    mh = "mh",
    mn = "mn",
    na = "na",
    nv = "nv",
    nd = "nd",
    nr = "nr",
    ng = "ng",
    ne = "ne",
    no = "no",
    nb = "nb",
    nn = "nn",
    ii = "ii",
    oc = "oc",
    oj = "oj",
    or = "or",
    om = "om",
    os = "os",
    pi = "pi",
    ps = "ps",
    fa = "fa",
    pl = "pl",
    pt = "pt",
    pa = "pa",
    qu = "qu",
    ro = "ro",
    rm = "rm",
    rn = "rn",
    ru = "ru",
    se = "se",
    sm = "sm",
    sg = "sg",
    sa = "sa",
    sc = "sc",
    sr = "sr",
    sn = "sn",
    sd = "sd",
    si = "si",
    sk = "sk",
    sl = "sl",
    so = "so",
    st = "st",
    es = "es",
    su = "su",
    sw = "sw",
    ss = "ss",
    sv = "sv",
    tl = "tl",
    ty = "ty",
    tg = "tg",
    ta = "ta",
    tt = "tt",
    te = "te",
    th = "th",
    bo = "bo",
    ti = "ti",
    to = "to",
    ts = "ts",
    tn = "tn",
    tr = "tr",
    tk = "tk",
    tw = "tw",
    ug = "ug",
    uk = "uk",
    ur = "ur",
    uz = "uz",
    ve = "ve",
    vi = "vi",
    vo = "vo",
    wa = "wa",
    cy = "cy",
    wo = "wo",
    xh = "xh",
    yi = "yi",
    yo = "yo",
    za = "za",
    zu = "zu"
}
export enum QuestionnaireSource {
    shEHRd = "shEHRd",
    MedicalDataModels = "MedicalDataModels"
}
export enum QuestionResponseType {
    FreeText = "FreeText",
    SingleChoice = "SingleChoice",
    MultipleChoice = "MultipleChoice",
    Number = "Number",
    Date = "Date",
    Time = "Time",
    DateTime = "DateTime",
    TrueFalse = "TrueFalse",
    ImageAnnotation = "ImageAnnotation"
}
export enum SymptomType {
    Undefined = "Undefined",
    Localized = "Localized",
    Systemic = "Systemic"
}
export enum ContactPersonRole {
    Undefined = "Undefined",
    Mother = "Mother",
    Father = "Father",
    Parent = "Parent",
    Daughter = "Daughter",
    Son = "Son",
    Child = "Child",
    Sister = "Sister",
    Brother = "Brother",
    Sibling = "Sibling",
    HalfSisterSameMother = "HalfSisterSameMother",
    HalfSisterSameFather = "HalfSisterSameFather",
    HalfBrotherSameMother = "HalfBrotherSameMother",
    HalfBrotherSameFather = "HalfBrotherSameFather",
    HalfSiblingSameMother = "HalfSiblingSameMother",
    HalfSiblingSameFather = "HalfSiblingSameFather",
    MaleCousin = "MaleCousin",
    FemaleCousin = "FemaleCousin",
    Cousin = "Cousin",
    Niece = "Niece",
    Nephew = "Nephew",
    Aunt = "Aunt",
    Uncle = "Uncle",
    Grandmother = "Grandmother",
    Grandfather = "Grandfather",
    GreatAunt = "GreatAunt",
    GreatUncle = "GreatUncle",
    Granddaughter = "Granddaughter",
    Grandson = "Grandson",
    Grandchild = "Grandchild",
    PrimaryPhysician = "PrimaryPhysician",
    Guardian = "Guardian",
    Husband = "Husband",
    Wife = "Wife",
    Partner = "Partner",
    Friend = "Friend",
    Other = "Other"
}
export enum PractitionerRole {
    HealthProfessional = "HealthProfessional",
    Researcher = "Researcher",
    Pharmacist = "Pharmacist",
    Admin = "Admin"
}
export enum Sex {
    Indeterminate = "Indeterminate",
    Male = "Male",
    Female = "Female",
    Other = "Other"
}
export enum FamilyTreeRelationshipCharacteristic {
    Biological = "Biological",
    Social = "Social"
}
export enum FamilyTreeRelationshipType {
    Child = "Child",
    Parent = "Parent",
    Partner = "Partner"
}
export enum DiagnosticTestScaleType {
    Undefined = "Undefined",
    Quantitative = "Quantitative",
    Ordinal = "Ordinal",
    OrdinalOrQuantitative = "OrdinalOrQuantitative",
    Nominal = "Nominal",
    Freetext = "Freetext",
    Document = "Document",
    Set = "Set"
}
export enum SpecimenStatus {
    Planned = "Planned",
    Collected = "Collected",
    InTransport = "InTransport",
    ReceivedByLab = "ReceivedByLab",
    Analyzing = "Analyzing",
    Analyzed = "Analyzed",
    Destroyed = "Destroyed",
    Lost = "Lost"
}
export enum SpecimenMeasurementStatus {
    Planned = "Planned",
    Completed = "Completed",
    Cancelled = "Cancelled"
}
export enum HealthEventSeverity {
    Mild = "Mild",
    Moderate = "Moderate",
    Severe = "Severe",
    LifeThreatening = "LifeThreatening"
}
export enum ExportFileFormat {
    Json = "Json",
    Csv = "Csv"
}
export enum GitFileDiffType {
    Added = "Added",
    Removed = "Removed",
    Changed = "Changed"
}
export enum GitMergeConflictFileVersion {
    Base = "Base",
    Theirs = "Theirs",
    Ours = "Ours"
}
export enum AssigneeType {
    Practitioner = "Practitioner",
    Group = "Group"
}
export enum TodoListItemStatus {
    Open = "Open",
    InProgress = "InProgress",
    Done = "Done",
    Discarded = "Discarded"
}
export enum IcdSectionType {
    Chapter = "Chapter",
    Block = "Block",
    Category = "Category"
}
export enum LocationType {
    Country = "Country",
    City = "City"
}
export enum MicrobType {
    Undefined = "Undefined",
    Bacteria = "Bacteria",
    Virus = "Virus",
    Fungi = "Fungi",
    Helminths = "Helminths",
    Protozoa = "Protozoa",
    LiceMites = "LiceMites",
    Other = "Other"
}
export enum RiskFactorType {
    Undefined = "Undefined",
    Disease = "Disease",
    Behavior = "Behavior"
}
export enum TimeOfYear {
    Spring = "Spring",
    Summer = "Summer",
    Autumn = "Autumn",
    Winter = "Winter"
}
export enum ChecklistConditionType {
    AnyOf = "AnyOf",
    AllOf = "AllOf",
    Not = "Not",
    GreaterThan = "GreaterThan",
    GreaterThanOrEqual = "GreaterThanOrEqual",
    LessThan = "LessThan",
    LessThanOrEqual = "LessThanOrEqual",
    Equal = "Equal",
    NotEqual = "NotEqual",
    InList = "InList",
    MatchesRegex = "MatchesRegex"
}
export enum ChecklistItemType {
    Checkbox = "Checkbox",
    SingleChoice = "SingleChoice",
    MultipleChoice = "MultipleChoice",
    ClassificationReference = "ClassificationReference",
    ChecklistReference = "ChecklistReference",
    ConditionalChecklistReference = "ConditionalChecklistReference",
    Heading = "Heading"
}
export enum AccessFilterType {
    Undefined = "Undefined",
    Date = "Date",
    Category = "Category"
}
export enum AccessPermissions {
    None = "None",
    Read = "Read",
    Create = "Create",
    Modify = "Modify"
}
export enum AuthenticationErrorType {
    Ok = "Ok",
    UserNotFound = "UserNotFound",
    InvalidPassword = "InvalidPassword",
    AuthenticationMethodNotAvailable = "AuthenticationMethodNotAvailable",
    EmailNotVerified = "EmailNotVerified",
    AccountNotActivated = "AccountNotActivated"
}
export enum InstitutionPermissions {
    Undefined = "Undefined",
    ReadSchedule = "ReadSchedule",
    ModifySchedule = "ModifySchedule",
    EnrollMembers = "EnrollMembers",
    ChangeSettings = "ChangeSettings",
    DeleteInstitution = "DeleteInstitution",
    HandleOrders = "HandleOrders"
}
export enum LoginProvider {
    Unknown = "Unknown",
    Google = "Google",
    Twitter = "Twitter",
    Facebook = "Facebook",
    Microsoft = "Microsoft",
    LocalJwt = "LocalJwt"
}
export enum LoginType {
    Local = "Local",
    External = "External"
}
export enum PatientInformationCategory {
    Undefined = "Undefined",
    BasicInformation = "BasicInformation",
    FamilyInformation = "FamilyInformation",
    Observations = "Observations",
    AdmissionHistory = "AdmissionHistory",
    Documents = "Documents",
    Notes = "Notes",
    Medications = "Medications",
    Diagnosis = "Diagnosis",
    Genome = "Genome"
}
export enum SharedAccessType {
    Unknown = "Unknown",
    HealthProfessional = "HealthProfessional",
    Emergency = "Emergency",
    Institution = "Institution"
}
export enum DataRepresentationType {
    Model = "Model",
    ViewModel = "ViewModel"
}
export enum StudyEnrollementState {
    Undefined = "Undefined",
    ParticipationOffered = "ParticipationOffered",
    Eligible = "Eligible",
    Enrolled = "Enrolled",
    Excluded = "Excluded",
    Rejected = "Rejected",
    Left = "Left"
}
export enum StudyStaffRole {
    Undefined = "Undefined",
    Investigator = "Investigator"
}
export enum DiseaseMonitoringLevel {
    Unnamed = "Unnamed",
    Named = "Named"
}
export enum PostalCodesType {
    Single = "Single",
    List = "List",
    Range = "Range",
    Any = "Any"
}
export enum AccessLogFormat {
    Json = "Json",
    Csv = "Csv"
}
export enum ImportStatus {
    Unknown = "Unknown",
    Success = "Success",
    Invalid = "Invalid",
    Conflict = "Conflict",
    SkippedNotNew = "SkippedNotNew",
    WrongPerson = "WrongPerson"
}
export enum DeductionType {
    Voucher = "Voucher"
}
export enum OrderItemType {
    General = "General",
    EmergencyCard = "EmergencyCard"
}
export enum OrderStatus {
    Placed = "Placed",
    Acknowledged = "Acknowledged",
    PaymentReceived = "PaymentReceived",
    Shipped = "Shipped",
    Completed = "Completed",
    CancelledByCustomer = "CancelledByCustomer",
    Rejected = "Rejected",
    LostDuringShipping = "LostDuringShipping",
    ReturnedByPostalService = "ReturnedByPostalService",
    BeingReturnedByCustomer = "BeingReturnedByCustomer",
    ReturnedByCustomer = "ReturnedByCustomer"
}
export enum PaymentMethod {
    BankTransfer = "BankTransfer",
    CreditCard = "CreditCard",
    Paypal = "Paypal"
}
export enum VoucherAmountType {
    FixedAmount = "FixedAmount",
    Percentage = "Percentage"
}
export enum AccountType {
    Undefined = "Undefined",
    Sharer = "Sharer",
    HealthProfessional = "HealthProfessional",
    Researcher = "Researcher",
    EmergencyGuest = "EmergencyGuest",
    Admin = "Admin",
    Pharmacy = "Pharmacy",
    PublicHealthAgency = "PublicHealthAgency"
}
export enum CloudStorageConnectionStatus {
    Active = "Active",
    Faulted = "Faulted"
}
export enum CloudStorageProvider {
    Dropbox = "Dropbox"
}
export enum AppointmentStatus {
    Proposed = "Proposed",
    Pending = "Pending",
    Booked = "Booked",
    Arrived = "Arrived",
    Fulfilled = "Fulfilled",
    Cancelled = "Cancelled",
    Noshow = "Noshow",
    EnteredInError = "EnteredInError",
    CheckedIn = "CheckedIn",
    Waitlist = "Waitlist"
}
export enum EncounterStatus {
    Planned = "Planned",
    Arrived = "Arrived",
    Triaged = "Triaged",
    InProgress = "InProgress",
    Onleave = "Onleave",
    Finished = "Finished",
    Cancelled = "Cancelled",
    EnteredInError = "EnteredInError",
    Unknown = "Unknown"
}
export enum DayOfWeek {
    Sunday = "Sunday",
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday"
}
export enum EventStatus {
    Preparation = "Preparation",
    InProgress = "InProgress",
    NotDone = "NotDone",
    OnHold = "OnHold",
    Stopped = "Stopped",
    Completed = "Completed",
    EnteredInError = "EnteredInError",
    Unknown = "Unknown"
}
export enum Currencies {
    AED = "AED",
    AFN = "AFN",
    ALL = "ALL",
    AMD = "AMD",
    ANG = "ANG",
    AOA = "AOA",
    ARS = "ARS",
    AUD = "AUD",
    AWG = "AWG",
    AZN = "AZN",
    BAM = "BAM",
    BBD = "BBD",
    BDT = "BDT",
    BGN = "BGN",
    BHD = "BHD",
    BIF = "BIF",
    BMD = "BMD",
    BND = "BND",
    BOB = "BOB",
    BOV = "BOV",
    BRL = "BRL",
    BSD = "BSD",
    BTN = "BTN",
    BWP = "BWP",
    BYN = "BYN",
    BZD = "BZD",
    CAD = "CAD",
    CDF = "CDF",
    CHE = "CHE",
    CHF = "CHF",
    CHW = "CHW",
    CLF = "CLF",
    CLP = "CLP",
    CNY = "CNY",
    COP = "COP",
    COU = "COU",
    CRC = "CRC",
    CUC = "CUC",
    CUP = "CUP",
    CVE = "CVE",
    CZK = "CZK",
    DJF = "DJF",
    DKK = "DKK",
    DOP = "DOP",
    DZD = "DZD",
    EGP = "EGP",
    ERN = "ERN",
    ETB = "ETB",
    EUR = "EUR",
    FJD = "FJD",
    FKP = "FKP",
    GBP = "GBP",
    GEL = "GEL",
    GGP = "GGP",
    GHS = "GHS",
    GIP = "GIP",
    GMD = "GMD",
    GNF = "GNF",
    GTQ = "GTQ",
    GYD = "GYD",
    HKD = "HKD",
    HNL = "HNL",
    HRK = "HRK",
    HTG = "HTG",
    HUF = "HUF",
    IDR = "IDR",
    ILS = "ILS",
    IMP = "IMP",
    INR = "INR",
    IQD = "IQD",
    IRR = "IRR",
    ISK = "ISK",
    JEP = "JEP",
    JMD = "JMD",
    JOD = "JOD",
    JPY = "JPY",
    KES = "KES",
    KGS = "KGS",
    KHR = "KHR",
    KMF = "KMF",
    KPW = "KPW",
    KRW = "KRW",
    KWD = "KWD",
    KYD = "KYD",
    KZT = "KZT",
    LAK = "LAK",
    LBP = "LBP",
    LKR = "LKR",
    LRD = "LRD",
    LSL = "LSL",
    LYD = "LYD",
    MAD = "MAD",
    MDL = "MDL",
    MGA = "MGA",
    MKD = "MKD",
    MMK = "MMK",
    MNT = "MNT",
    MOP = "MOP",
    MRU = "MRU",
    MUR = "MUR",
    MVR = "MVR",
    MWK = "MWK",
    MXN = "MXN",
    MXV = "MXV",
    MYR = "MYR",
    MZN = "MZN",
    NAD = "NAD",
    NGN = "NGN",
    NIO = "NIO",
    NOK = "NOK",
    NPR = "NPR",
    NZD = "NZD",
    OMR = "OMR",
    PAB = "PAB",
    PEN = "PEN",
    PGK = "PGK",
    PHP = "PHP",
    PKR = "PKR",
    PLN = "PLN",
    PYG = "PYG",
    QAR = "QAR",
    RON = "RON",
    RSD = "RSD",
    RUB = "RUB",
    RWF = "RWF",
    SAR = "SAR",
    SBD = "SBD",
    SCR = "SCR",
    SDG = "SDG",
    SEK = "SEK",
    SGD = "SGD",
    SHP = "SHP",
    SLL = "SLL",
    SOS = "SOS",
    SRD = "SRD",
    SSP = "SSP",
    STN = "STN",
    SVC = "SVC",
    SYP = "SYP",
    SZL = "SZL",
    THB = "THB",
    TJS = "TJS",
    TMT = "TMT",
    TND = "TND",
    TOP = "TOP",
    TRY = "TRY",
    TTD = "TTD",
    TVD = "TVD",
    TWD = "TWD",
    TZS = "TZS",
    UAH = "UAH",
    UGX = "UGX",
    USD = "USD",
    USN = "USN",
    UYI = "UYI",
    UYU = "UYU",
    UZS = "UZS",
    VEF = "VEF",
    VND = "VND",
    VUV = "VUV",
    WST = "WST",
    XAF = "XAF",
    XAG = "XAG",
    XAU = "XAU",
    XBA = "XBA",
    XBB = "XBB",
    XBC = "XBC",
    XBD = "XBD",
    XCD = "XCD",
    XDR = "XDR",
    XOF = "XOF",
    XPD = "XPD",
    XPF = "XPF",
    XPT = "XPT",
    XSU = "XSU",
    XTS = "XTS",
    XUA = "XUA",
    XXX = "XXX",
    YER = "YER",
    ZAR = "ZAR",
    ZMW = "ZMW",
    ZWL = "ZWL"
}
export enum DiagnosticReportStatus {
    Registered = "Registered",
    Partial = "Partial",
    Preliminary = "Preliminary",
    Final = "Final",
    Amended = "Amended",
    Corrected = "Corrected",
    Appended = "Appended",
    Cancelled = "Cancelled",
    EnteredInError = "EnteredInError",
    Unknown = "Unknown"
}
export enum ConditionClinicalStatusCodes {
    Active = "Active",
    Recurrence = "Recurrence",
    Relapse = "Relapse",
    Inactive = "Inactive",
    Remission = "Remission",
    Resolved = "Resolved"
}
