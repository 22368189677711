import { Nav, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { resolveText } from '../../../sharedCommonComponents/helpers/Globalizer';
import ToolsDropdownMenu from './ToolsDropdownMenu';
import { SharedUrls } from '../../../sharedHealthComponents/navigation/Urls';
import { ShehrdUrls } from '../../navigation/Urls';

interface HealthProfessionalMenuProps {}

export const HealthProfessionalMenu = (props: HealthProfessionalMenuProps) => {

    const navigate = useNavigate();
    return (
        <Nav>
            <NavDropdown title={resolveText("Menu_Patients")}>
                <NavDropdown.Item onClick={() => navigate(ShehrdUrls.PATIENTS)}>{resolveText("Menu_MyPatients")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShehrdUrls.CREATE_EMERGENCY)}>{resolveText("Menu_Emergency")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.APPOINTMENTS)}>{resolveText("Menu_Appointments")}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={resolveText("Menu_HealthServices")}>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.INSTITUTIONS)}>{resolveText("Menu_Institutions")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.SERVICES)}>{resolveText("Menu_FindServices")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.CREATE_SERVICE)}>{resolveText("Menu_CreateService")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.SERVICEREQUESTS)}>{resolveText("Menu_ServiceRequests")}</NavDropdown.Item>
            </NavDropdown>
            <ToolsDropdownMenu />
            <NavDropdown title={resolveText("Menu_Config")}>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.QUESTIONNAIRES)}>{resolveText("Menu_Questionnaires")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.LABTEST_PACKAGES)}>{resolveText("Menu_TestPackages")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.TASK_PACKAGES)}>{resolveText("Menu_TaskPackages")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.EQUIPMENT_MEASUREMENTS)}>{resolveText("Menu_EquipmentMeasurements")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(SharedUrls.DRUGS)}>{resolveText("Menu_Drugs")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(ShehrdUrls.ANONYMOUS_FORMS)}>{resolveText("Menu_AnonymousForms")}</NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );

}